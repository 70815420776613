import React, {
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useNavigate,
	useOutletContext,
	useParams,
} from 'react-router-dom';

// ROUTES
import PATHS from '@routes/paths';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// MODULES
import {
	getURL,
} from '@modules/utils';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import Button from '@components/button';
import {
	Dropzone,
	DropzoneChildDefault,
	DropzoneChildOver,
	DropzoneChildProcessing,
} from '@components/dropzone';
import Image from '@components/image';
// import {
// 	MediaCard,
// } from '@components/media-card';

// ASSETS
import DropYourFileImage from '@assets/images/lpdipro/drop_your_file.png';

// STYLES
import styles from './media.module.scss';

const Media = () => {
	const { offerid, mediatype } = useParams();
	const currentOfferId = !isNaN(Number(offerid)) ? Number(offerid) : null;
	const navigate = useNavigate();

	const validationType = [
		'documents',
		'photos',
		'plans',
		'videos',
	];

	if (!validationType.includes(mediatype)) {
		navigate(getURL(PATHS.OFFERS.EDIT.MEDIA, {
			':offerid': `${currentOfferId}`,
			':mediatype': 'photos',
		}));
	}

	const {t} = useTranslation();
	const {
		offerState,
		setOfferState,
		isDesktopResolution,
	}: PageOfferContextProps = useOutletContext();

	const [
		isProcessing,
		setIsProcessing,
	] =	useState(false);

	const dropzoneElement = (
		<Dropzone
			isProcessing={isProcessing}
			onDrop={(acceptedFiles) => {
				console.log('acceptedFiles', acceptedFiles);
				setIsProcessing(true);
			}}
		>
			<DropzoneChildDefault
				className={styles.dropzone}
				key='1'
			>
				<span
					className={[
						styles.line,
						styles.grey
					].join(' ')}
				>
					{t('page.offers_create_edit.media.image_weight_should_not_exceed')}
				</span>
				<span className={styles.line}>
					{t('format.capitalize', {
						text: t('page.offers_create_edit.media.drag_and_drop_your_photos')
					})}
				</span>
				<span
					className={[
						styles.line,
						styles.surroundedbydashes
					].join(' ')}
				>{t('format.capitalize', {
						text: t('page.offers_create_edit.media.or')
					})}</span>
				<Button
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.media.select_your_files')
					})}
				/>
			</DropzoneChildDefault>
			<DropzoneChildOver
				className={[
					styles.dropzone,
					styles.active
				].join(' ')}
				key='2'
			>
				<Image
					alt={t('format.capitalize', {
						text: t('page.offers_create_edit.media.drop_your_files_here')
					})}
					className={styles.dropzone__image}
					src={DropYourFileImage}
				/>
				<span className={styles.line}>
					{t('format.capitalize', {
						text: t('page.offers_create_edit.media.drop_your_files_here')
					})}
				</span>
			</DropzoneChildOver>,
			<DropzoneChildProcessing
				className={[
					styles.dropzone,
					styles.isprocessing
				].join(' ')}
				key='4'
			>
				<span className={styles.line}>
					{t('format.capitalize', {
						text: t('page.offers_create_edit.media.file_upload_in_progress')
					})}
				</span>
			</DropzoneChildProcessing>
		</Dropzone>
	);

	return (
		<div className={`${styles.section} ${styles.section__full}`}>
			<div className={styles.step_progress}>
				{t('format.capitalize', {
					text: `${t('page.offers_create_edit.step')} 5/8`
				})}
			</div>
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.media.import_your_media')
				})}
			</div>
			<div className={styles.button_list}>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Default}
					counter={0}
					iconLeft={'picture'}
					iconStyle={EnumFontStyle.REGULAR}
					isActive={mediatype === 'photos'}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.media.photos'),
					})}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					to={getURL(PATHS.OFFERS.EDIT.MEDIA, {
						':offerid': `${currentOfferId}`,
						':mediatype': 'photos',
					})}
				/>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Default}
					counter={0}
					iconLeft={'plan'}
					iconStyle={EnumFontStyle.REGULAR}
					isActive={mediatype === 'plans'}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.media.plans'),
					})}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					to={getURL(PATHS.OFFERS.EDIT.MEDIA, {
						':offerid': `${currentOfferId}`,
						':mediatype': 'plans',
					})}
				/>
			</div>
			{dropzoneElement}
			<ValidationForm
				isDesktopResolution={isDesktopResolution}
				isValid={offerState?.addresses?.length ? true : false}
				offerState={offerState}
				setOfferState={setOfferState}
			/>
		</div>
	);
};

export default withTranslation()(Media);
