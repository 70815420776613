import {
	combineReducers,
	configureStore,
} from '@reduxjs/toolkit';
import {
	createMigrate,
	persistReducer,
	persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from '@stores/lpdipro/reducers';
import migrations from '@stores/lpdipro/migrations';

const persistConfig = {
	key: 'root',
	version: 11,
	storage,
	migrate: createMigrate(migrations, {
		debug: false
	})
};

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.APP_ENV_NAME_FRONTEND !== 'production',
	middleware: [
	]
});

type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch
const persistor = persistStore(store);

export {
	store,
	persistor,
	RootState,
	AppDispatch,
};
