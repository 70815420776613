import React, {
	Dispatch,
	RefObject,
	SetStateAction,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	matchRoutes,
	useLocation,
	useNavigate,
} from 'react-router-dom';

// CONFIG
import PATHS from '@routes/paths';
import {
	OFFER_ENTRY_LOCATION_CREATE_ROUTES,
} from '@routes/lpdipro/private/offer-entry';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// EXCEPTIONS
import AddOfferError from '@exceptions/AddOfferError';
import EditOfferError from '@exceptions/EditOfferError';

// CONFIG
import {
	createOffer,
	editOffer,
	getOffer,
} from '../config/fetch';

// LAYOUTS
import {
	OfferStateProps,
} from '@layouts/Pages/PageOfferEntry';

// COMPONENTS
import Form from '@components/form';
import Button from '@components/button';

// STYLES
import styles from './validation-form.module.scss';
import {
	getURL,
} from '@modules/utils';

interface ValidationFormProps {
	offerState: OfferStateProps;
	isDesktopResolution: boolean;
	inputs?: JSX.Element;
	isValid: boolean;
	formRef?: RefObject<HTMLFormElement>;
	setIsOpen?: Dispatch<SetStateAction<boolean>>;
	setOfferState: Dispatch<SetStateAction<OfferStateProps>>;
	setIsLoading?: Dispatch<SetStateAction<boolean>>;
}

const ValidationForm = ({
	offerState,
	isDesktopResolution,
	inputs,
	isValid,
	formRef,
	setIsOpen,
	setOfferState,
}: ValidationFormProps): JSX.Element => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const location = useLocation();

	const onSubmitSuccess = async () => {
		if (isValid) {
			if (matchRoutes(OFFER_ENTRY_LOCATION_CREATE_ROUTES, location.pathname)?.length) {
				createOffer(offerState).then(responseParsed => {
					if (responseParsed.status === 200) {
						const goToOfferInfos = getURL(PATHS.OFFERS.EDIT.INFOS, {
							':offerid': `${responseParsed.payload.id}`,
						});
						const offerId = responseParsed.payload.id;
						getOffer(offerId).then(responseParsed => {
							if (responseParsed.status === 200) {
								setOfferState({
									id: offerId,
									...responseParsed.payload,
								});

								navigate(goToOfferInfos);
							} else {
								throw new EditOfferError(responseParsed.statusText);
							}
						});
					} else {
						throw new AddOfferError(responseParsed.statusText);
					}
				});
			} else {
				editOffer(offerState).then(responseParsed => {
					console.log('responseParsed', responseParsed);
					if (responseParsed.status === 200) {
						setOfferState({
							id: offerState.id,
							...responseParsed.payload,
						});
					} else {
						throw new EditOfferError(responseParsed.statusText);
					}
				});
			}
		} else {
			if (setIsOpen) setIsOpen(true);
			setTimeout(() => {
				const submitEvent = new Event('submit', {
					bubbles: true,
					cancelable: true,
				});
				formRef?.current?.dispatchEvent(submitEvent);
			}, 0);
		}
	};

	return (
		<Form
			className={styles.form}
			onSuccess={onSubmitSuccess}
		>
			<>{inputs}</>
			<div className={styles.button_group}>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.cancel')
					})}
					size={isDesktopResolution ? EnumButtonSize.DEFAULT : EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					type={EnumButtonType.BUTTON}
				/>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.continue')
					})}
					size={isDesktopResolution ? EnumButtonSize.DEFAULT : EnumButtonSize.SMALL}
					type={EnumButtonType.SUBMIT}
				/>
			</div>
		</Form>
	);
};

export default withTranslation()(ValidationForm);
