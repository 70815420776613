import {
	OFFER_ENTRY_CONTACTS_ROUTES,
	OFFER_ENTRY_INFOS_ROUTES,
	OFFER_ENTRY_LOCATION_ROUTES,
	OFFER_ENTRY_MEDIA_ROUTES,
} from '@routes/lpdipro/private/offer-entry';

export const configurationSteps = [
	{
		name: 'location',
		matchRoute: OFFER_ENTRY_LOCATION_ROUTES,
	},
	{
		name: 'infos',
		matchRoute: OFFER_ENTRY_INFOS_ROUTES,
	},
	{
		name: 'contacts',
		matchRoute: OFFER_ENTRY_CONTACTS_ROUTES,
	},
	{
		name: 'lots',
		matchRoute: [
		],
	},
	{
		name: 'media',
		matchRoute: OFFER_ENTRY_MEDIA_ROUTES,
	},
	{
		name: 'services',
		matchRoute: [
		],
	},
	{
		name: 'terms',
		matchRoute: [
		],
	},
	{
		name: 'summary',
		matchRoute: [
		],
	},
];
