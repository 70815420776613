import React, {
	ChangeEvent,
	Children,
	cloneElement,
	FC,
	isValidElement,
	ReactNode,
	RefObject,
	useState,
} from 'react';
import {
	CriteriaMode,
	useForm,
	UseFormProps,
	FormProvider,
	FieldValues,
	SubmitHandler,
} from 'react-hook-form';
import {
	DevTool,
} from '@hookform/devtools';

// CONFIG
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// ENUMS
import {
	EnumFormMode,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumQueryMethods,
} from '@enums/query.enum';

// STYLES
import styles from './form.module.scss';

export interface ResponseParsedProps {
	status: number;
	statusText: string;
	payload: Record<string, unknown>;
}

interface FormProps extends UseFormProps {
	action?: string;
	autoComplete?: string;
	children: ReactNode;
	className?: string;
	'data-testid'?: string;
	innerRef?: RefObject<HTMLFormElement>;
	method?: EnumQueryMethods;
	mode?: EnumFormMode;
	name?: string;
	noValidate?: boolean;
	onError?: (data: FieldValues | ResponseParsedProps) => void;
	onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onRequestSuccess?: (responseParsed: ResponseParsedProps) => void;
	onSuccess?: (data: FieldValues | ResponseParsedProps) => void;
	showDevTool?: boolean;
}

const Form: FC<FormProps> = ({
	action,
	autoComplete,
	children,
	className,
	'data-testid': dataTestid,
	defaultValues,
	innerRef,
	method = EnumQueryMethods.POST,
	mode = EnumFormMode.ON_SUBMIT,
	name,
	noValidate = true,
	onInputChange,
	onError,
	onRequestSuccess,
	onSuccess,
	showDevTool,
}) => {
	// Define react hook form settings
	const formSettings = {
		defaultValues: defaultValues,
		mode: mode as EnumFormMode,
		reValidateMode: EnumFormMode.ON_CHANGE,
		shouldFocusError: true,
		criteriaMode: 'all' as CriteriaMode,
	};

	const methods = useForm(formSettings);
	const { handleSubmit, formState: { errors, dirtyFields }, setFocus } = methods;

	const cssClasses = [
		styles.form,
	];

	if (className) cssClasses.push(className);

	const [
		localClasses,
		setLocalClasses,
	] = useState(cssClasses);

	const [
		stateIsSubmitting,
		setStateIsSubmitting
	] = useState(false);

	const nameEncoded = (name: string) => {
		let newName = name?.replace(/[[']+/g, '#');
		newName = newName?.replace(/[\]']+/g, '$');
		return newName;
	};

	const nameDecoded = (name: string) => {
		let newName = name?.replace(/[#']+/g, '[');
		newName = newName.replace(/[$']+/g, ']');
		return newName;
	};

	const submitForm = (data: FieldValues) => {
		// Because of [brackets] in field names from Symfony we have to do some data manipulation like that :

		const formatedData = {
		} as FieldValues;
		Object.keys(data).forEach((dataItemKey: string) => {
			formatedData[nameDecoded(dataItemKey)] = data[dataItemKey];
		});
		if (action) {
			fetch(action, {
				...APP_CONF_VARS.request.default,
				body: JSON.stringify(formatedData),
				method: method,
			}).then((response) => {
				return response.json();
			}).then(function (responseParsed) {
				// Set default success
				let isSuccessfullResponse: boolean | void = true;
				isSuccessfullResponse = ![
					401,
					403
				].includes(responseParsed.status);

				if (!isSuccessfullResponse) {
					// some status codes are server errors but must be considered as success
					isSuccessfullResponse = responseParsed.statusText.startsWith('success');
				}

				if (isSuccessfullResponse) {
					// Allows to execute checks before define success in targeted action
					if (onRequestSuccess) isSuccessfullResponse = onRequestSuccess(responseParsed);
					isSuccessfullResponse = typeof isSuccessfullResponse === 'undefined' ? true : isSuccessfullResponse;
				}

				if (isSuccessfullResponse) {
					if (responseParsed?.payload?.redirect_url) {
						// REDIRECT ASKED BY BACKEND
						window.location.href = responseParsed.payload.redirect_url;
						return;
					}
					if (onSuccess) onSuccess(responseParsed);
				} else {
					setLocalClasses([
						...localClasses,
						styles.action_failed
					]);
					onSubmitFormError(responseParsed);
				}

			}).catch((responseParsed) => {
				onSubmitFormError(responseParsed);
			}).finally(() => {
				setStateIsSubmitting(false);
			});
		} else {
			if (onSuccess) onSuccess(data);
			setStateIsSubmitting(false);
		}
	};

	const onSubmitFormSuccess: SubmitHandler<ResponseParsedProps> = (data) => {
		submitForm(data);
	};

	const onSubmitFormError = (data: FieldValues) => {
		// Find the first field with an error and set focus to it
		for (const [
			key
		] of Object.entries(errors)) {
			setFocus(key);
			break;
		}

		if (onError) onError(data);
		setStateIsSubmitting(false);
	};

	// Manage input changes
	const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const indexInputChanged = localClasses.indexOf('input_changed');

		if (indexInputChanged !== -1) {
			localClasses.splice(indexInputChanged, 1);
		}
		setLocalClasses([
			...localClasses.filter(className => className !== styles.action_failed),
			'input_changed'
		]);

		if (onInputChange) onInputChange(event);
	};

	const validInputType = [
		EnumComponentType.INPUT_CHECKBOX,
		EnumComponentType.INPUT_DATE_PICKER,
		EnumComponentType.INPUT_MULTI_SELECT,
		EnumComponentType.INPUT_PASSWORD,
		EnumComponentType.INPUT_PASSWORD_WITH_VALIDATION,
		EnumComponentType.INPUT_RADIO_GROUP,
		EnumComponentType.INPUT_SEARCH,
		EnumComponentType.INPUT_SEARCH_WITH_DROPDOWN,
		EnumComponentType.INPUT_SELECT,
		EnumComponentType.INPUT_TEXT,
		EnumComponentType.INPUT_TEXT_WITH_UNIT,
		EnumComponentType.INPUT_TIME_PICKER,
		EnumComponentType.TEXTAREA,
		EnumComponentType.INPUT_TOOGLE_BUTTON,
	];

	const renderChildrenFormRecursively = (children: ReactNode): ReactNode => {
		return Children.map(children, (child) => {
			if (isValidElement(child)) {
				const displayName = ((child.type as FC)?.displayName || undefined) as EnumComponentType;

				if (displayName && validInputType.includes(displayName)) {
					const cssInputClasses = [
						styles.input
					];
					if (child.props.className) cssInputClasses.push(child.props.className);

					return cloneElement(child, {
						...child.props,
						name: nameEncoded(child.props.name),
						className: cssInputClasses.join(' '),
						disabled: stateIsSubmitting,
						invalid: errors[nameEncoded(child.props.name)] || child.props.invalid,
						methods: methods,
						validationType: mode,
						dirty: dirtyFields[child.props.name],
						onChange: (event: ChangeEvent<HTMLInputElement>) => {
							if (child.props.onChange) child.props.onChange(event);
							handleOnInputChange(event);
						},
					});
				} else if (displayName && displayName === EnumComponentType.INPUT_HIDDEN) {
					return cloneElement(child, {
						...child.props,
						name: nameEncoded(child.props.name),
						invalid: errors[nameEncoded(child.props.name)] || child.props.invalid,
						methods: methods,
					});
				} else if (displayName && displayName === EnumComponentType.BUTTON) {
					const cssInputClasses = [
						styles.button,
					];
					if (child.props.className) cssInputClasses.push(child.props.className);
					return cloneElement(child, {
						...child.props,
						className: cssInputClasses.join(' '),
						disabled: stateIsSubmitting,
						loader: child.props.type === EnumButtonType.SUBMIT ? stateIsSubmitting : false,
					});
				} else {
					return cloneElement(child, {
						...child.props,
						children: renderChildrenFormRecursively(child.props.children),
					});
				}
			}
			return child;
		});
	};

	const formDevtool = showDevTool ? (
		<DevTool
			control={methods.control}
			placement="top-right"
		/>
	) : null;

	return (
		<>
			{formDevtool}
			<FormProvider {...methods}>
				<form
					action={action}
					autoComplete={autoComplete}
					className={localClasses.join(' ')}
					data-testid={dataTestid}
					method={method}
					name={name}
					noValidate={noValidate}
					ref={innerRef}
					onSubmit={async (e) => {
						e.preventDefault();
						setStateIsSubmitting(true);
						await handleSubmit(onSubmitFormSuccess, onSubmitFormError)();
					}}
				>
					{renderChildrenFormRecursively(children)}
				</form>
			</FormProvider>
		</>
	);
};

export default Form;
