// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KiaSQPMl{display:flex;flex-direction:row;padding:1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.KiaSQPMl{padding:1.42857143rem}}.XoqUdlZu{display:flex;flex-direction:column;width:100%}.XoqUdlZu .Z0PsPt2U{color:var(--color-primary);margin-bottom:1.42857143rem;font-weight:600;font-size:1rem}.XoqUdlZu .aCkr7vxW{margin-bottom:1.42857143rem;font-size:0.85714286rem}.XoqUdlZu .btnb3nnZ{margin-bottom:1.42857143rem;font-size:1.14285714rem;font-weight:600;line-height:1.6em}.XoqUdlZu .btnb3nnZ:first-letter{display:block;text-transform:uppercase}.rhj8QVeE{color:var(--color-boulder)}.b_VA3Tqp{margin-bottom:1.14285714rem;padding:0 0.85714286rem}.FGMDrtY7{position:relative}.FGMDrtY7:before,.FGMDrtY7:after{background-color:var(--color-border-default);content:"";display:block;height:1Px;position:absolute;top:50%;width:5.57142857rem;z-index:1}.FGMDrtY7:before{right:100%}.FGMDrtY7:after{left:100%}@media screen and (width <= 640px){.FGMDrtY7{display:none}}.xIJR1apf{align-items:center;border:2Px dashed var(--color-border-focus);border-radius:0.28571429rem;cursor:pointer;display:flex;flex-direction:column;justify-content:center;margin-bottom:1.42857143rem;min-height:15.5rem;padding:1.42857143rem;text-align:center;transition:border .3s}.xIJR1apf._djQF3pT{background-color:hsla(var(--color-background-primary-h), var(--color-background-primary-s), var(--color-background-primary-l), 0.05)}.xIJR1apf.kMBGn8sx{cursor:not-allowed}.xBEikhbk{align-items:center;display:flex;justify-content:center;margin-bottom:1.14285714rem}.KX0e63cN{display:flex;flex-wrap:wrap;margin-bottom:1.42857143rem}.KX0e63cN .BziX6x51{margin-right:0.57142857rem}@media screen and (min-width: 768Px)and (orientation: landscape){.KX0e63cN .BziX6x51{margin-right:1.14285714rem}}.KX0e63cN .BziX6x51:last-child{margin-right:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `KiaSQPMl`,
	"section__full": `XoqUdlZu`,
	"step_progress": `Z0PsPt2U`,
	"legend": `aCkr7vxW`,
	"title": `btnb3nnZ`,
	"grey": `rhj8QVeE`,
	"line": `b_VA3Tqp`,
	"surroundedbydashes": `FGMDrtY7`,
	"dropzone": `xIJR1apf`,
	"active": `_djQF3pT`,
	"isprocessing": `kMBGn8sx`,
	"dropzone__image": `xBEikhbk`,
	"button_list": `KX0e63cN`,
	"button": `BziX6x51`
};
export default ___CSS_LOADER_EXPORT___;
