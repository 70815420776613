import React, {
	MouseEvent,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useNavigate,
	useParams,
} from 'react-router-dom';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

// TYPES
import {
	DemandJsonPrivate,
} from '@@types/Demand';

// ENUMS
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumSteps,
} from '@enums/steps.enum';
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';

// ROUTES
import PATHS from '@routes/paths';

// STORES
import {
	DeviceDisplays,
	DeviceOrientations,
} from '@stores/_slices/device';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import {
	demandUpdateOfferState,
	demandsSetList,
} from '@stores/_slices/demands';
import {
	MatchingOfferDemands,
	matchingOffersDemandsRemoveDemands,
	matchingOffersDemandsUpdateDemands,
} from '@stores/_slices/matching_offers_demands';

// SLICES
import {
	appSetRoute,
} from '@stores/_slices/app';
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	getAnnouncement as fetchGetAnnouncement,
	getDemands as fetchGetDemands,
} from './config/fetch';
import {
	OfferJson,
} from '@@types/Offer';
import {
	userLogout,
} from '@stores/_slices/user';
import {
	clearOffers,
	offerUpdate,
	offerUpdateCounter,
} from '@stores/_slices/offers';

// MODULES
import dataUtils from '@modules/data-utils';
import {
	includesSome,
} from '@modules/filters';

// CONFIG
import {
	columnTableConf,
} from './config/columnTable.config';
import {
	filterValueMappings,
	getOptionsFilters,
} from './config/filterValueMappings';

import {
	setCommercializationStep,
} from './config/fetch';

// LAYOUTS
import ContentLoaderHeader from '@layouts/Pages/PageMatchingOfferDemands/contentLoader/contentLoaderHeader';
import ContentLoaderDemand from '@layouts/Pages/PageMatchingOfferDemands/contentLoader/contentLoaderDemand';
import DemandRow from '@layouts/Pages/PageMatchingOfferDemands/DemandRow';
import Filter from '@layouts/Filter/filter';
import Header from '@layouts/Pages/PageMatchingOfferDemands/header';
import ModalDemandFiltersMobile from '@layouts/Modals/ModalDemandFiltersMobile';
import Page from '@layouts/Pages/Page';

// COMPONENTS
import Image from '@components/image';
import Modal, {
	ModalSize,
} from '@components/modal';
import Paragraph from '@components/paragraph';
import Table from '@components/table';
import Button, {
} from '@components/button';

// STYLES
import styles from './PageMatchingOfferDemands.module.scss';

// IMAGES
import matchedImage from '@assets/images/lpdipro/icon_matched.jpg';
import proposedImage from '@assets/images/lpdipro/icon_proposed.jpg';
import appliedImage from '@assets/images/lpdipro/icon_applied.jpg';
import refusedImage from '@assets/images/lpdipro/icon_refused.jpg';
import ignoredImage from '@assets/images/lpdipro/icon_ignored.jpg';

export interface BodyRequestMatching {
	demands?: number[];
	commercialization_step?: string;
	states?: string[];
}

function PageMatchingOfferDemands() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { offerid, step } = useParams();
	const currentOfferId = !isNaN(Number(offerid)) ? Number(offerid) : null;
	const commercializationStep = step as EnumSteps || EnumSteps.MATCHED;

	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const actualLanguage = useSelector((state: ReducerInstance) => state.app.instance.localization.actualLanguage);
	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP || (device.display === DeviceDisplays.TABLET && device.orientation === DeviceOrientations.LANDSCAPE);

	const offersGlobalState = useSelector((state: ReducerInstance) => state.offers.instance).collection.filter((offer: OfferJson) => offer?.id === currentOfferId) as OfferJson[];
	const offerGlobalState = offersGlobalState && offersGlobalState.length ? offersGlobalState[0] : null;

	const demandsGlobalState = useSelector((state: ReducerInstance) => state.demands.instance);
	const matchingDemandsOffersState = useSelector((state: ReducerInstance) => state.matchings_offers_demands.instance).collection.filter((item: MatchingOfferDemands) => item?.offer_id === currentOfferId) as MatchingOfferDemands[];
	const matchingDemandsOfferState = matchingDemandsOffersState && matchingDemandsOffersState.length ? matchingDemandsOffersState[0] : null;

	const [
		isContentLoaded,
		setIsContentLoaded
	] = useState({
		offer: false,
		demands: false
	});

	const [
		columnFilters,
		setColumnFilters
	] = useState([
	]);

	const [
		globalFilter,
		setGlobalFilter
	] = useState('');

	const [
		reinitSelection,
		setReinitSelection
	] = useState(false);

	const handleOnClickBookmarked = (event: MouseEvent<HTMLElement>, newBodyRequest: BodyRequestMatching) => {
		event.stopPropagation();

		setCommercializationStep(currentOfferId, newBodyRequest).then((responseParsed) => {
			if (responseParsed.status === 200) {

				dispatch(demandUpdateOfferState({
					demands: newBodyRequest.demands,
					commercialization_step: newBodyRequest.commercialization_step,
					offer_state: newBodyRequest.states
				}));

			}
		});
	};

	const handleOnClickCommercializationStep = (event: MouseEvent<HTMLElement>, args: BodyRequestMatching) => {
		event.stopPropagation();
		dispatch(clearStatusMsgs());
		dispatch(clearToastMsgs());
		setCommercializationStep(currentOfferId, args).then((responseParsed) => {
			if (responseParsed.status === 200) {

				setReinitSelection(true);

				dispatch(matchingOffersDemandsRemoveDemands({
					demand_ids: args.demands,
					current_commercialization_step: commercializationStep,
					new_commercialization_step: args.commercialization_step,
					offer_id: currentOfferId,
				}));

				dispatch(offerUpdateCounter({
					demand_ids: args.demands,
					current_commercialization_step: commercializationStep,
					new_commercialization_step: args.commercialization_step,
					offer_id: currentOfferId,
				}));

				dispatch(addToastMsg({
					message: t('format.capitalize', {
						text: t(`page.matching_offer_demands.moved_to${args.demands.length > 1 ? '_other' : ''}`, {
							'%step%': t('format.capitalize', {
								text: t(`page.matching_offer_demands.steps.${args.commercialization_step}.title`)
							})
						}),
					}),
				}));

			} else {
				dispatch(addToastMsg({
					message: t('format.capitalize', {
						text: t([
							`status.${responseParsed.statusText}`,
							'status.default'
						]),
					}),
					theme: EnumStatusTheme.ERROR,
				}));
			}
		});
	};

	const getAnnouncement = async () => {
		await fetchGetAnnouncement(currentOfferId).then((responseParsed) => {
			//event triggered on page load
			if (responseParsed.payload?.redirect_url) {
				// REDIRECT ASKED BY BACKEND
				window.location.href = responseParsed.payload.redirect_url;
				return;
			}
			switch (responseParsed.status) {
				case 200:
					dispatch(offerUpdate({
						offer_id: currentOfferId,
						offer: responseParsed.payload,
					}));
					setIsContentLoaded({
						...isContentLoaded,
						offer: true,
					});
					break;
				case 401:
				case 403:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t('status.unauthorized_access'),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
					navigate(PATHS.HOMEPAGE);
					break;
				default: {
					if (responseParsed.error === 'access_denied') {
						// STORING THE PREVIOUS ROUTE BEFORE AUTH
						dispatch(appSetRoute({
							name: 'from',
							url: location.pathname
						}));
						dispatch(userLogout());
						dispatch(clearOffers());
						dispatch(addStatusMsg({
							message: t('status.error_session'),
							theme: EnumStatusTheme.ERROR,
						}));
					} else {
						dispatch(addToastMsg({
							message: t('format.capitalize', {
								text: t([
									`status.${responseParsed.statusText}`,
									'status.default'
								]),
							}),
							theme: EnumStatusTheme.ERROR,
						}));
						navigate(PATHS.HOMEPAGE);
					}
				}
			}
		});
	};

	const getDemands = async () => {
		await fetchGetDemands(currentOfferId, commercializationStep).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(demandsSetList({
						collection: responseParsed.payload.demands.collection,
						count: responseParsed.payload.demands.count
					}));
					dispatch(matchingOffersDemandsUpdateDemands({
						offer_id: currentOfferId,
						demand_ids: responseParsed.payload.demands.collection.map((demand: DemandJsonPrivate) => demand.id),
					}));
					setIsContentLoaded({
						...isContentLoaded,
						demands: true,
					});
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}
		}).catch(() => {
			setIsContentLoaded({
				...isContentLoaded,
				demands: true,
			});
		});
	};

	const didLogRef = useRef(false);

	useEffect(() => {
		if (didLogRef.current === false) {
			didLogRef.current = true;
			dispatch(clearStatusMsgs());
			dispatch(clearToastMsgs());

			getAnnouncement();
		}
	}, [
	]);

	useEffect(() => {
		if (isContentLoaded.offer) {
			setGlobalFilter('');
			setColumnFilters([
			]);
			setIsContentLoaded({
				...isContentLoaded,
				demands: false,
			});
			getDemands();
		}
	}, [
		commercializationStep,
		isContentLoaded.offer
	]);

	const demandsMatchedWithOffer = isContentLoaded.demands && matchingDemandsOfferState?.demand_ids?.length ? demandsGlobalState.collection.filter(demand => matchingDemandsOfferState.demand_ids.includes(demand.id)) : [
	];
	const formatedData = demandsMatchedWithOffer.length ? demandsMatchedWithOffer.map(demand => dataUtils.flattenObject(demand)) : [
	];

	let commercializationStepImage = null;
	switch (commercializationStep) {
		case EnumSteps.APPLIED:
			commercializationStepImage = appliedImage;
			break;
		case EnumSteps.REFUSED:
			commercializationStepImage = refusedImage;
			break;
		case EnumSteps.IGNORED:
			commercializationStepImage = ignoredImage;
			break;
		case EnumSteps.PROPOSED:
			commercializationStepImage = proposedImage;
			break;
		default:
			commercializationStepImage = matchedImage;
	}

	const noResultElement = isContentLoaded.demands ? (
		<Paragraph
			className={styles.no_result}
		>
			<div className={styles.message}>
				<Image
					className={styles.images}
					src={commercializationStepImage}
				/>
				{t('format.capitalize', {
					text: t(`page.matching_offer_demands.steps.${commercializationStep}.no_result${columnFilters.length || globalFilter.length ? '_filtered' : ''}`)
				})}
				<span className={styles.description}>
					<span>
						{t('format.capitalize', {
							text: t(`page.matching_offer_demands.steps.${commercializationStep}.no_result_description${columnFilters.length || globalFilter.length ? '_filtered' : ''}`)
						})}
					</span>
				</span>

			</div>
		</Paragraph>
	) : null;

	const [
		demandIdsSelected, // eslint-disable-line
		setDemandIdsSelected
	] = useState<number[]>([
	]);

	const [
		isOpenFilterModal,
		setIsOpenFilterModal
	] = useState(false);

	const isPlural = matchingDemandsOfferState?.demand_ids?.length > 1 ? true : false;

	const headingText = `${t('format.capitalize', {
		text: t(`page.matching_offer_demands.steps.${commercializationStep}.title${isPlural ? '_other' : ''}`)
	})}\u00A0(${matchingDemandsOfferState?.demand_ids?.length})`;

	const headingElement = isContentLoaded.demands ? (
		<Paragraph
			className={styles.heading}
			title={headingText}
		>
			{matchingDemandsOfferState?.demand_ids?.length ? t('format.capitalize', {
				text: t(`page.matching_offer_demands.steps.${commercializationStep}.description`)
			}) : null}
		</Paragraph >
	) : undefined;

	const tableTop = (
		<div className={styles.table__top}>
			{isDesktopResolution ? headingElement : undefined}
			<Filter
				commercializationStep={commercializationStep}
				filterValueMappings={filterValueMappings}
				getOptionsFilters={getOptionsFilters}
				globalFilter={globalFilter}
				isDesktopResolution={isDesktopResolution}
				setColumnFilters={setColumnFilters}
				setGlobalFilter={setGlobalFilter}
				setIsOpenFilterModal={setIsOpenFilterModal}
			/>
		</div>
	);

	const commercializationActions = () => {
		let actionsList = null;
		switch (commercializationStep) {
			case EnumSteps.MATCHED: {
				actionsList = (
					<>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'share-arrow'}
							label={t('format.capitalize', {
								text: t('general.action.proposed')
							})}
							name='proposed'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.PRIMARY}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'proposed',
									demands: demandIdsSelected,
								});
							}}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'trash-alt'}
							label={t('format.capitalize', {
								text: t('general.action.ignored')
							})}
							name='ignored'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'ignored',
									demands: demandIdsSelected,
								});
							}}
						/>
					</>
				);
				break;
			}
			case EnumSteps.PROPOSED: {
				actionsList = (
					<>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'folder'}
							label={t('format.capitalize', {
								text: t('general.action.applied')
							})}
							name='applied'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.PRIMARY}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'applied',
									demands: demandIdsSelected,
								});
							}}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'times'}
							label={t('format.capitalize', {
								text: t('general.action.refused')
							})}
							name='refused'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'refused',
									demands: demandIdsSelected,
								});
							}}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'trash-alt'}
							label={t('format.capitalize', {
								text: t('general.action.ignored')
							})}
							name='ignored'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'ignored',
									demands: demandIdsSelected,
								});
							}}
						/>
					</>
				);
				break;
			}
			case EnumSteps.APPLIED: {

				// const classSeparator = isDesktopResolution ? 'separator' : '';
				actionsList = (
					<>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'arrow-back'}
							label={t('format.capitalize', {
								text: t('general.action.cancel_apply')
							})}
							name='propose'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.PRIMARY}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'proposed',
									demands: demandIdsSelected,
								});
							}}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							hasBoxShadow={true}
							iconLeft={'trash-alt'}
							label={t('format.capitalize', {
								text: t('general.action.ignored')
							})}
							name='ignored'
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							onClick={event => {
								handleOnClickCommercializationStep(event, {
									commercialization_step: 'ignored',
									demands: demandIdsSelected,
								});
							}}
						/>
					</>
				);
				break;
			}
			case EnumSteps.IGNORED:
			case EnumSteps.REFUSED: {
				actionsList = (
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						hasBoxShadow={true}
						iconLeft={'share-arrow'}
						label={t('format.capitalize', {
							text: t('general.action.proposed')
						})}
						name='proposed'
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						onClick={event => {
							handleOnClickCommercializationStep(event, {
								commercialization_step: 'proposed',
								demands: demandIdsSelected,
							});
						}}
					/>
				);
				break;
			}

		}
		return <div className={styles.action_list}>{actionsList}</div>;
	};

	const configurationActionBarTable = {
		type: t('module.offer.name_other'),
		actions: commercializationActions(),
		commercializationStep: commercializationStep,
		demandId: demandIdsSelected,
	};

	const demandsList = isContentLoaded.demands ? (
		<Table
			className={styles.table}
			columnFilters={columnFilters}
			columns={columnTableConf}
			configurationActionBar={(commercializationStep === EnumSteps.MATCHED) ? configurationActionBarTable : undefined}
			customNoResult={noResultElement}
			data={formatedData}
			filterFns={(row) => includesSome(row, columnFilters)}
			globalFilter={globalFilter}
			identifier='table_demands'
			options={{
				canSelect: true,
				hasHeader: isDesktopResolution,
				pagination: {
					nbItemsPerPage: 10,
				},
				topChildren: tableTop,
			}}
			reinitSelection={reinitSelection}
			renderSubComponent={(props) => {
				if (props !== null) {
					const filteredDemandJson = demandsGlobalState?.collection?.filter((demand: DemandJsonPrivate) => demand.id === props.original.id);
					return filteredDemandJson.length ? (
						<DemandRow
							{...props}
							className={styles.demand_row}
							commercializationStep={commercializationStep}
							demandJson={filteredDemandJson[0] as DemandJsonPrivate}
							isDesktopResolution={isDesktopResolution}
							isSelected={props.isSelected}
							offerId={currentOfferId}
							onClickBookmarked={handleOnClickBookmarked}
							onClickCommercializationStep={handleOnClickCommercializationStep}
						/>
					) : undefined;
				}
			}}
			setComponentSelectedIds={setDemandIdsSelected}
			setReinitSelection={setReinitSelection}
		/>
	) : <ContentLoaderDemand />;

	const contentMatchingRef = useRef<HTMLDivElement>(null);

	return (
		<Page className={styles.page}>
			<div
				className={styles.content}
				ref={contentMatchingRef}
			>
				{isContentLoaded.offer ? (
					<Header
						actualLanguage={actualLanguage}
						contentMatchingRef={contentMatchingRef}
						isDesktopResolution={isDesktopResolution}
						offerGlobalState={offerGlobalState}
					/>
				) : (
					<ContentLoaderHeader />
				)}
				<div className={styles.content_matching}>
					{isDesktopResolution ? undefined : headingElement}
					{demandsList}
				</div>
			</div>
			{isOpenFilterModal ? (
				<Modal
					isDesktopResolution={isDesktopResolution}
					isOpen={isOpenFilterModal}
					size={ModalSize.DEFAULT}
					title={t('format.capitalize', {
						text: t('modals.filter.title')
					})}
					onClose={() => {
						setIsOpenFilterModal(false);
					}}
				>
					<ModalDemandFiltersMobile
						columnFilters={columnFilters}
						commercializationStep={commercializationStep}
						setColumnFilters={setColumnFilters}
						setIsOpenFilterModal={setIsOpenFilterModal}
					/>
				</Modal>
			) : undefined}
		</Page>
	);
}

export default PageMatchingOfferDemands;
