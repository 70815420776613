// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uZlnNwQk{display:flex;flex-direction:row;padding:1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.uZlnNwQk{padding:1.42857143rem}}.ShzqkbBd{display:flex;flex-direction:column;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.ShzqkbBd{flex-shrink:0;width:40.71428571rem}}.ShzqkbBd .Bd2E2HDU{color:var(--color-primary);margin-bottom:1.42857143rem;font-weight:600;font-size:1rem}.ShzqkbBd .kljabhML{margin-bottom:1.42857143rem;font-size:0.85714286rem}.ShzqkbBd .tIyt3XtJ{margin-bottom:1.42857143rem;font-size:1.14285714rem;font-weight:600;line-height:1.6em}.ShzqkbBd .tIyt3XtJ:first-letter{display:block;text-transform:uppercase}.hipnr87W{display:flex;justify-content:center;width:100%}.hipnr87W .D3c8i_1w{padding-top:2.14285714rem;width:auto}.XXQsOGh4{display:flex;flex-direction:row;gap:1.42857143rem}.XXQsOGh4>*{flex:1}.IGEyKwn9{display:flex;flex-direction:column}.XyYWDCRC{margin-bottom:2.14285714rem}.XyYWDCRC>div{margin:0 0 1.07142857rem}.XyYWDCRC>div:last-child{margin:0}.Rnp9xP_i{width:100%}.CIXIFSqh{margin-left:0.71428571rem;width:max-content}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `uZlnNwQk`,
	"section__left": `ShzqkbBd`,
	"step_progress": `Bd2E2HDU`,
	"legend": `kljabhML`,
	"title": `tIyt3XtJ`,
	"section__right": `hipnr87W`,
	"image": `D3c8i_1w`,
	"form_row": `XXQsOGh4`,
	"form_column": `IGEyKwn9`,
	"checkbox": `XyYWDCRC`,
	"full_select": `Rnp9xP_i`,
	"tooltip": `CIXIFSqh`
};
export default ___CSS_LOADER_EXPORT___;
