// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EnvDVGMf{display:flex;flex-direction:row;padding:1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.EnvDVGMf{padding:1.42857143rem}}.VpCeO5tX{display:flex;flex-direction:column;width:100%}.VpCeO5tX .XexhG_2R{color:var(--color-primary);margin-bottom:1.42857143rem;font-weight:600;font-size:1rem}.VpCeO5tX .AV0cLxiu{margin-bottom:1.42857143rem;font-size:0.85714286rem}.VpCeO5tX .C2ghSAPo{margin-bottom:1.42857143rem;font-size:1.14285714rem;font-weight:600;line-height:1.6em}.VpCeO5tX .C2ghSAPo:first-letter{display:block;text-transform:uppercase}.itUdPNOL{color:var(--color-boulder)}.jZ2l30Af{margin-bottom:1.14285714rem;padding:0 0.85714286rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `EnvDVGMf`,
	"section__full": `VpCeO5tX`,
	"step_progress": `XexhG_2R`,
	"legend": `AV0cLxiu`,
	"title": `C2ghSAPo`,
	"grey": `itUdPNOL`,
	"line": `jZ2l30Af`
};
export default ___CSS_LOADER_EXPORT___;
