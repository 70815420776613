import * as React from 'react';
import {
	APP_CONF_VARS, EnumImageFormatQuery,
} from '@appConf/vars.conf';
import {
	Dispatch,
	FormEvent,
	MouseEvent,
	SetStateAction,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import FETCHES from '@routes/fetches';
import moment from 'moment';

// STORES
import {
	demandOfferAddVisit,
	demandOffersUpdate,
} from '@stores/_slices/demands';
import {
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

//TYPES
import {
	Contact, ContactJson,
} from '@@types/Contact';
import {
	Collection,
} from '@@types/Collection';
import {
	DemandJsonPrivate,
	RecipientJson,
} from '@@types/Demand';
import {
	OfferJson,
} from '@@types/Offer';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// MODULES
import imagesModules from '@modules/images';
import * as utils from '@modules/utils';

//COMPONENTS
import Button, {
} from '@components/button';
import InputRadioGroup from '@components/form/input-radio-group';
import InputText from '@components/form/input-text';
import InputDatePicker from '@components/form/input-date-picker';
import InputTimePicker from '@components/form/input-time-picker';
import Textarea from '@components/form/textarea';
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

//STYLES
import styles from './ModalVisit.module.scss';

interface ConfirmationProps {
	address?: string;
	date?: string;
	time?: string;
	contact_id?: string;
	comment?: string;
}

interface ModalVisitProps {
	address?: string;
	contacts?: Collection<ContactJson, Contact>;
	demandId?: number;
	images?: string;
	language?: string;
	recipientCompany?: RecipientJson['company'];
	offerId?: number;
	time?: moment.Moment;
	setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
	setDataModalConfirmation?: Dispatch<SetStateAction<ConfirmationProps>>;
}

const ModalVisit = ({
	address,
	contacts,
	demandId,
	images,
	language,
	recipientCompany,
	offerId,
	time,
	setIsOpenModal,
	setDataModalConfirmation,
}: ModalVisitProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const demandGlobalState = useSelector((state: ReducerInstance) => state.demands.instance).collection.filter((item: DemandJsonPrivate) => demandId == item?.id)[0] as DemandJsonPrivate;

	const dataAddress = {
		title: address,
		image_url: imagesModules.updateImageUrls(images, EnumImageFormatQuery.SQUARE) as string || 'unknown',
		descriptions: [
			{
				value: t('format.capitalize', {
					text: t('general.to_visit')
				}),
				type: 'string'
			},
		]
	};

	const [
		isDisabled,
		setIsDisabled
	] = useState(false);

	let contactElement = (
		<div className={styles.no_result}>
			{t('format.capitalize', {
				text: t('general.internal_information.no_contact')
			})}
		</div>
	);

	const currentOffer = demandGlobalState.offers.collection.filter((offer: OfferJson) => offer.id === offerId)[0] as OfferJson;
	if (contacts?.collection?.length) {
		const optionsElement = contacts.collection.map((contact: Contact, index: number) => {
			const dataContact = {
				title: contact.full_name,
				id: contact?.id.toString(),
				image_url: imagesModules.updateImageUrls(contact?.logo?.url, EnumImageFormatQuery.USERCARD) as string || undefined,
				descriptions: [
					{
						value: contact?.company_name || null,
						type: 'company',
						required: true,
					},
					{
						value: contact?.phone || contact?.mobile || null,
						type: 'tel',
						required: true,
					},
					{
						value: contact?.email || null,
						type: 'email',
						required: true,
					}
				],
			};
			return {
				label: <UserCard
					data={dataContact}
					theme={ThemeUserCard.SQUARE}
					visitData={contact.suggested_contact_for_visit}
				/>,
				className: styles.contact_input,
				id: 'visit_' + `${index}`,
				name: 'contact_id',
				value: contact.id,
			};
		});
		contactElement = (
			<InputRadioGroup
				className={styles.contact}
				name="announcement_contacts"
				options={optionsElement}
			/>
		);
	}

	const isAlreadyMarkToPlan = demandGlobalState?.offers?.collection ? currentOffer.states.includes('visit_to_plan') : false;
	const hasVisit = [
		'visit_planned',
		'visit_expired',
	];

	let isAlreadyPlanned = false;
	currentOffer.states.forEach(state => {
		if (hasVisit.includes(state)) {
			isAlreadyPlanned = true;
		}
	});

	const fetchVisitRequest = async () => {
		const bodyRequest = {
			action: 'to_plan',
		};
		const bodyRequestDeleteVisit = {
			reason: 'other',
		};
		// if the login exists in store we don't need to query it
		const response = await fetch(utils.getURL(FETCHES.private.demands.offer.visit, {
			':demandid': `${demandId}`,
			':offerid': `${offerId}`,
		}), {
			...APP_CONF_VARS.request.default,
			method: isAlreadyPlanned ? 'PATCH' : isAlreadyMarkToPlan ? 'DELETE' : 'POST',
			body: JSON.stringify(isAlreadyMarkToPlan ? bodyRequestDeleteVisit : bodyRequest),
		});
		return response;
	};

	const handleOnClickCancel = ((event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setIsDisabled(true);
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		const updatedOffers: OfferJson[] = [
		];
		const hiddenLabel = [
			'visit_to_plan',
			'visit_planned'
		];
		if (isAlreadyMarkToPlan) {
			fetchVisitRequest().then((resp) => {
				setIsOpenModal(false);
				switch (resp.status) {
					case 204:
						demandGlobalState.offers.collection.forEach((offer: OfferJson) => {
							if (offer.id === offerId) {
								const offerUpdated = {
									...offer,
									states: [
										...offer.states.filter(state => !hiddenLabel.includes(state)),
									]
								};
								updatedOffers.push(offerUpdated);
							} else {
								updatedOffers.push(offer);
							}
						});
						dispatch(demandOffersUpdate({
							demand_id: demandId,
							offers: {
								...demandGlobalState.offers,
								collection: [
									...updatedOffers
								]
							}
						}));
						break;
					default:
						dispatch(addToastMsg({
							message: t('format.capitalize', {
								text: t([
									`status.${resp.statusText}`,
									'status.default',
								]),
							}),
							theme: EnumStatusTheme.ERROR,
						}));
				}
			}).catch(err => {
				setIsOpenModal(false);
				console.warn('ERROR : ', err);
			});
		} else {
			fetchVisitRequest().then((resp) => {
				return resp.json();
			}).then((responseParsed) => {
				setIsOpenModal(false);
				switch (responseParsed.status) {
					case 200:
						demandGlobalState.offers.collection.forEach((offer: OfferJson) => {
							if (offer.id === offerId) {
								const offerUpdated = {
									...offer,
									states: [
										responseParsed?.payload?.state ? responseParsed.payload.state : 'visit_to_plan',
										...offer.states.filter(state => !hiddenLabel.includes(state)),
									],
								};
								updatedOffers.push(offerUpdated);
							} else {
								updatedOffers.push(offer);
							}
						});
						dispatch(demandOffersUpdate({
							demand_id: demandId,
							offers: {
								...demandGlobalState.offers,
								collection: [
									...updatedOffers
								]
							}
						}));
						break;
					default:
						dispatch(addToastMsg({
							message: t('format.capitalize', {
								text: t([
									`status.${responseParsed.statusText}`,
									'status.default',
								]),
							}),
							theme: EnumStatusTheme.ERROR,
						}));
				}
			}).catch(err => {
				setIsOpenModal(false);
				console.warn('ERROR : ', err);
			});
		}
	});

	const handleOnSubmit = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault(); // Prevent default submission
		setIsDisabled(true);
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		const target = event.target as HTMLElement;
		const inputsValues = {
		} as { [key: string]: string };
		const inputsForm = [
			...target.closest('div').querySelectorAll('input'),
			...target.closest('div').querySelectorAll('textarea')
		];
		inputsForm.forEach((input: HTMLInputElement) => {
			switch (true) {
				case input.name === 'appointment_date':
				case input.name === 'appointment_time':
				case input.name === 'appointment_time_end':
				case input.name === 'subject':
				case input.name === 'place':
				case input.type === 'radio' && input.checked:
				case input.type === 'textarea': {
					inputsValues[input.name] = input.value;
					break;
				}
			}
		});

		const hiddenLabel = [
			'visit_to_plan',
			'visit_planned',
			'visit_visited',
		];

		const bodyRequest = {
			announcement_contact_id: inputsValues.contact_id,
			subject: inputsValues.subject,
			address: inputsValues.place,
			date: inputsValues.appointment_date,
			time: inputsValues.appointment_time,
			comment: inputsValues.information,
			action: 'planned',
		};

		fetch(utils.getURL(FETCHES.private.demands.offer.visit, {
			':demandid': `${demandId}`,
			':offerid': `${offerId}`,
		}), {
			...APP_CONF_VARS.request.default,
			method: isAlreadyMarkToPlan || isAlreadyPlanned ? 'PATCH' : 'POST',
			body: JSON.stringify(bodyRequest),
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(demandOfferAddVisit({
						demand_id: demandId,
						offer_id: offerId,
						states: [
							responseParsed?.payload?.state ? responseParsed.payload.state : 'visit_planned',
							...currentOffer.states.filter(state => !hiddenLabel.includes(state)),
						],
						visit: {
							comment: bodyRequest?.comment,
							state: bodyRequest.action,
							contact_id: Number(bodyRequest.announcement_contact_id),
							date_time: `${bodyRequest.date} ${bodyRequest.time}`,
						}
					}));

					setDataModalConfirmation({
						comment: inputsValues.information,
						contact_id: inputsValues.contact_id,
						date: new Date(inputsValues.appointment_date).toLocaleDateString(language),
						is_modify: false,
						subject: inputsValues.subject,
						time: inputsValues.appointment_time,
						time_end: inputsValues.appointment_time_end,
					} as ConfirmationProps);
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default',
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}
		}).catch(err => {
			console.warn('ERROR : ', err);
			setIsOpenModal(false);
		});
	};

	return (
		<form
			onSubmit={event => {
				handleOnSubmit(event);
			}}
		>
			<div className={`${styles.visit}`}>
				<div className={styles.col}>
					<UserCard
						data={dataAddress}
						theme={ThemeUserCard.SQUARE}
					/>
					<h3>
						{t('format.capitalize', {
							text: t('modals.visit.plan.subtitle')
						})} :
					</h3>
					{contactElement}
				</div>
				<div className={styles.col}>
					<div className={styles.form_elements}>
						<InputText
							defaultValue={t('format.capitalize', {
								text: t('modals.visit.plan.subject_value', {
									company: recipientCompany.name
								})
							})}
							label={t('format.capitalize', {
								text: t('general.subject')
							})}
							name='subject'
							required={true}
						/>
						<InputText
							defaultValue={address}
							label={t('format.capitalize', {
								text: t('general.place')
							})}
							name='place'
							required={true}
						/>
						<InputDatePicker
							label={t('format.capitalize', {
								text: t('general.form.input.type.date_picker.label')
							})}
							name="appointment_date"
							required={true}
						/>
						<div className={styles.time}>
							<InputTimePicker
								label={t('format.capitalize', {
									text: t('general.form.input.type.time_picker.label_start')
								})}
								name='appointment_time'
								required={true}
								value={time ? moment(time, 'HH:mm') : moment()}
							/>
							<InputTimePicker
								defaultValue={moment().add(1, 'hours')}
								label={t('format.capitalize', {
									text: t('general.form.input.type.time_picker.label_end')
								})}
								name='appointment_time_end'
								required={true}
								value={time ? moment(time, 'HH:mm').add(1, 'hours') : moment().add(1, 'hours')}
							/>
						</div>
						<Textarea
							label={t('format.capitalize', {
								text: t('general.useful_info')
							})}
							name="information"
							rows={6}
						/>
					</div>
				</div>
				<div
					className={styles.footer_buttons}
				>
					<Button
						corners={EnumButtonCorners.Square}
						disabled={isDisabled}
						label={t('format.capitalize', {
							text: isAlreadyMarkToPlan ? t('general.action.remove_visit_to_plan') : t('general.action.mark_as_visit_to_plan')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						type={EnumButtonType.RESET}
						onClick={handleOnClickCancel}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						disabled={!contacts?.collection?.length || isDisabled}
						label={t('format.capitalize', {
							text: t('general.action.plan_my_visit')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						type={EnumButtonType.SUBMIT}
					/>
				</div>
			</div>
		</form>
	);
};

export default ModalVisit;
