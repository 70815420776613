
import React from 'react';
import {
	ReactNode,
} from 'react';
import {
	withTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// STORES
import {
	userLogout,
} from '@stores/_slices/user';
import {
	clearDemands,
} from '@stores/_slices/demands';
import {
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumThemeLink,
} from '@enums/link.enum';

// COMPONENTS
import LinkNav from '@components/link-nav';

// MODULES
import * as utils from '@modules/utils';

// ROUTES
import FETCHES from '@routes/fetches';

// STYLES
import styles from './link-to-logout.module.scss';

interface LinkToLogoutProps {
	className?: string;
	children?: ReactNode;
}

// Will generate a link to simulate login in or logoout states
function LinkToLogout({
	children,
	className,
	...otherProps
}: LinkToLogoutProps) {
	const dispatch = useDispatch();

	const cssClasses = [
		styles.linktolog
	];
	if (className) cssClasses.push(className);

	return (
		<LinkNav
			{...otherProps}
			className={cssClasses.join(' ')}
			fontWeight={EnumFontStyle.REGULAR}
			theme={EnumThemeLink.NAKED}
			onClick={(event) => {
				event.preventDefault();
				dispatch(clearToastMsgs());
				dispatch(clearStatusMsgs());
				// the app is loading if logout begins
				fetch(utils.getURL(FETCHES.private.logout), {
					...APP_CONF_VARS.request.default,
				}).then((responseParsed) => {
					switch (responseParsed.status) {
						case 200:
							dispatch(userLogout());
							dispatch(clearDemands());
							break;
						case 403: {
							const errorTypesToLogout = [
								'unique_connection_error'
							];
							// Only some error types needs a logout action
							if (errorTypesToLogout.includes(responseParsed.statusText)) {
								dispatch(userLogout());
								dispatch(clearDemands());
							}
							break;
						}
						default:
					}
				}).catch(err => {
					console.warn('ERROR : ', err);
				});
			}}
		>{children}</LinkNav>
	);
}

export default withTranslation()(LinkToLogout);
