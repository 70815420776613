import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	withTranslation,
} from 'react-i18next';
import {
	useSelector,
} from 'react-redux';
import {
	matchRoutes,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';

// ENUMS
import {
	EnumMediaType,
} from '@enums/media.enum';

// CONFIG
import PATHS from '@routes/paths';
import {
	OFFER_ENTRY_LOCATION_CREATE_ROUTES,
} from '@routes/lpdipro/private/offer-entry';
import {
	getOffer,
	getOfferEnums,
} from './config/fetch';

// REDUCERS
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// SLICES
import {
	DeviceDisplays, DeviceOrientations,
} from '@stores/_slices/device';

// LAYOUTS
import Page from '@layouts/Pages/Page';
import Header from '@layouts/Pages/PageOfferEntry/header';

// STYLES
import styles from './PageOfferEntry.module.scss';

export interface AddressesProps {
	id: number;
	decoded: Record<string, unknown>;
	icon?: string;
	is_main: boolean;
	is_valid?: boolean;
	street_raw: string;
	pretty_address?: string;
	type?: string;
}

export interface MediaProps {
	id: number;
	is_main: boolean;
	url: string;
	title?: string;
	filename?: string;
	type: EnumMediaType;
}

export interface OfferStateProps {
	addresses?: AddressesProps[];
	building_name?: string;
	caz_name?: string;
	id?: number;
	infos?: Record<string, unknown>[] | Record<string, undefined>;
	is_private?: boolean;
	is_published?: boolean;
	leases?: {
		details?: {
			[key: string]: string | number;
		};
		rental_ref_lease_type_id?: number;
	}[];
	mandate?: {
		type: string;
		is_owner: boolean;
	};
	programm_name?: string;
	ref_building_commercial_zones?: string;
	ref_property_nature_main_id?: number;
	steps?: {
		[key: string]: boolean;
	};
	transport_access?: string;
	types?: string[];
}
export interface OfferEnumsStateProps {
	mandate_type?: string[];
	ref_building_commercial_zones?: string[];
	ref_property_nature_announcements: {
			key: string;
			value: number;
		}[];
	rental_ref_lease_types?: {
		key: string;
		value: number;
	}[];
	types?: string[];
	visibilities: {
		key: string;
		value: number;
	}[];
}

export interface PageOfferContextProps {
	isDesktopResolution: boolean;
	offerState?: OfferStateProps;
	setOfferState: (Dispatch<SetStateAction<OfferStateProps>>);
	offerEnumsState?: OfferEnumsStateProps;
}

const PageOfferEntry = () => {
	const {offerid} = useParams();
	const currentOfferId = !isNaN(Number(offerid)) ? Number(offerid) : undefined;
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP || (device.display === DeviceDisplays.TABLET && device.orientation === DeviceOrientations.LANDSCAPE);

	const navigate = useNavigate();

	const contentEntryOfferRef = useRef(null);

	const location = useLocation();

	const [
		isLoaded,
		setIsLoaded,
	] = useState(false);

	const [
		offerState,
		setOfferState
	] = useState();

	// A GARDER PENDANT LE DEVELOPPEMENT
	console.log('offerState', offerState);

	const [
		offerEnumsState,
		setOfferEnumsState,
	] = useState(undefined);

	useEffect(() => {
		if (matchRoutes(OFFER_ENTRY_LOCATION_CREATE_ROUTES, location.pathname)?.length) {
			getOfferEnums().then(responseParsed => {
				if (responseParsed.status === 200) {
					setOfferEnumsState(responseParsed.payload);
					setIsLoaded(true);
				}
			});
		} else {
			const fetchOffer = async () => {
				await getOfferEnums().then(responseParsed => {
					if (responseParsed.status === 200) {
						setOfferEnumsState(responseParsed.payload);
					}
				});
				await getOffer(currentOfferId).then(responseParsed => {
					if (responseParsed.status === 200) {
						setOfferState({
							...responseParsed.payload,
							id: currentOfferId,
						});
						setIsLoaded(true);
					} else {
						navigate(PATHS.HOMEPAGE);
					}
				}).catch(() => {
					navigate(PATHS.HOMEPAGE);
				});
			};

			fetchOffer();
		}
	}, [
	]);

	return isLoaded ? (
		<Page className={styles.PageOfferEntry}>
			<div
				className={styles.PageOfferEntry__content}
				ref={contentEntryOfferRef}
			>
				<Header
					contentEntryOfferRef={contentEntryOfferRef}
					isDesktopResolution={isDesktopResolution}
					offerState={offerState}
				/>
				<Outlet
					context={{
						offerState: offerState,
						setOfferState: setOfferState,
						isDesktopResolution: isDesktopResolution,
						offerEnumsState: offerEnumsState,
					}}
				/>
			</div>
		</Page>
	) : undefined;
};

export default withTranslation()(PageOfferEntry);
