import React from 'react';
import {
	ChangeEvent,
	MouseEvent,
	ReactElement,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import PATHS from '@routes/paths';

// ENUMS
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumQueryMethods,
} from '@enums/query.enum';

// HOOKS
import useClickOutsideEffect from '@hooks/useClickOutsideEffect/hook.useClickOutsideEffect';

// EXCEPTIONS
import SearchWithApiError from '@exceptions/SearchWithApiError';

// HOOKS
import useDebouncedEffect from '@hooks/useDebouncedEffect/hook.useDebouncedEffect';

// MODULES
import * as utils from '@modules/utils';
import utilsText from '@modules/text';

// COMPONENTS
import Icon from '@components/icon';
import InputText, {
	InputTextProps,
} from '@components/form/input-text';
import SuggestionList from '@components/suggestion-list';
import Suggestion from '@components/suggestion-list/suggestion';

// STYLING
import styles from './input-search-with-dropdown.module.scss';

interface dataToShowProps {
	[key: string]: string | object;
	activity?: string;
	address?: string;
	company?: {
		name?: string;
	};
	data_type?: string;
	displayText?: string;
	icon?: string;
	link?: string;
	name?: string;
	type?: string;
	staff?: never | null;
}

interface searchProps extends dataToShowProps {
	hits?: {
		data?: dataToShowProps | Array<dataToShowProps>;
	};
}
interface InputSearchWithDropdownProps extends InputTextProps {
	api?: {
		url?: string;
	};
	hasShadow?: boolean;
	iconIsToggled?: boolean;
	restrictedElement?: ReactElement;
	onClickOutside?: (event: MouseEvent<HTMLInputElement>) => void;
	onClickResult?: (event: MouseEvent<HTMLInputElement>, dataToShow: dataToShowProps) => void;
	onDisplayResults?: (data: dataToShowProps) => void;
	onError?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputSearchWithDropdown = ({
	'data-testid': dataTestid,
	defaultValue,
	disabled = false,
	hasBorder = true,
	hasShadow = false,
	initialValue,
	restrictedElement,
	onClickOutside,
	onClickResult,
	onChange,
	onDisplayResults,
	onError,
	size,
	...otherProps
}: InputSearchWithDropdownProps): JSX.Element => {
	const { t } = useTranslation();

	const hookRef = useRef();
	const ref = hookRef;

	const classes = [
		styles.search
	];

	if (size) classes.push(styles[`${'size__' + size}`]);
	if (hasShadow) classes.push(styles.shadow);

	const [
		cssClasses,
		setCssClasses
	] = useState(classes);

	const initialState: InputSearchWithDropdownProps = {
		...otherProps,
		disabled,
		value: (initialValue || defaultValue) as string,
		iconIsToggled: initialValue?.length ? true : false || defaultValue?.length ? true : false,
	};

	const [
		state,
		setState,
	] = useState(initialState);

	const [
		isLoading,
		setIsLoading,
	] = useState(false);

	const [
		searchResults,
		setSearchResults
	] = useState(undefined as searchProps);

	const [
		suggestions,
		setSuggestions
	] = useState([
	]);

	const [
		suggestionsHTML,
		setSuggestionsHTML
	] = useState(undefined as ReactElement);

	//Manage click on icon to clear input value
	const handleOnClickClearSearch = (event: MouseEvent<HTMLElement>) => {
		const icon = event.currentTarget;
		const input = icon.closest('div').querySelector('input');
		input.value = null;
		const newState: InputSearchWithDropdownProps = {
			...state,
			iconIsToggled: false,
			value: null,
		};
		setState(newState);
	};

	const cssClassesIconRightElement = [
		styles.icon,
		styles.icon__right,
	];
	const iconRightElement = (
		<Icon
			className={cssClassesIconRightElement.join(' ')}
			data-testid={`${dataTestid}-clearSearchIcon`}
			fontStyle={EnumFontStyle.LIGHT}
			name='times'
			onClick={handleOnClickClearSearch}
		/>
	);

	const noResultsElement = (
		<li
			className={styles.paragraph}
			data-testid={`${dataTestid}-noresults`}
			key='noresult'
		>
			<Icon
				className={styles.itemIcon}
				fontStyle={EnumFontStyle.LIGHT}
				name='search-slash'
			/>&nbsp;
			<span
				dangerouslySetInnerHTML={{
					__html: t('component.input.autosuggest.noresult', {
						url: PATHS.SEARCH.BUILDING.LEGACY
					})
				}}
			/>
		</li>
	);

	// Manage click outside input
	const handleOnClickOutside = (event: MouseEvent<HTMLInputElement>) => {
		setSuggestions(null);
		setSuggestionsHTML(null);
		if (onClickOutside) onClickOutside(event);
	};

	useClickOutsideEffect([
		ref
	], handleOnClickOutside);

	const searchWithApi = useCallback(async (value: string) => {
		/* istanbul ignore next */
		const onRequestSearchError = (error: ChangeEvent<HTMLInputElement>) => {
			if (onError) onError(error);
			setSuggestionsHTML(noResultsElement);
		};

		try {
			await fetch(`${state.api.url}`, {
				...APP_CONF_VARS.request.default,
				method: EnumQueryMethods.POST,
				headers: {
					...APP_CONF_VARS.request.default.headers,
				},
				body: JSON.stringify({
					q: value,
					filter: /* istanbul ignore next */ value.split(' ').length && value.split(' ').length < 2 ? 'data_type=\'company\'' : '' // TEMPORARY
				})
			})
				.then((resp) => {
					return resp.json();
				})
				.then(responseParsed => {
					if (responseParsed.status >= 400 && responseParsed.status <= 500) {
						throw new SearchWithApiError(responseParsed);
					} else {
						// This test exists to avoid previous request which can make more time to respond
						const newResults = {
							...responseParsed?.payload
						};
						setSearchResults(newResults);
					}
				}).finally(() => {
					setIsLoading(false);
				});
		} catch (error) {
			/* istanbul ignore next */
			if (error.name !== 'AbortError') {
				onRequestSearchError(error);
				setIsLoading(false);
			}
		}
	}, [
	]);

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>, actualState: InputSearchWithDropdownProps) => {
		const target = event.currentTarget;
		const newState: InputSearchWithDropdownProps = {
			...state,
			...actualState,
			iconIsToggled: target.value.length ? true : false
		};
		setState(newState);
		if (onChange) onChange(event, newState);
		setIsLoading(true);
	};

	const autosuggestRestrictedElement = restrictedElement ? (
		<li
			data-testid={`${dataTestid}-restricted`}
			key='autosuggest__restricted'
		>
			{restrictedElement}
		</li>
	) : null;

	const handleOnChangeValue = (value: string) => {
		const clearedInputValue = value?.replace(/\s/g, '');

		if (clearedInputValue?.length) {
			const isThereNotEnoughChars = clearedInputValue.length < APP_CONF_VARS.autosuggest.minNbInputChars;
			if (isThereNotEnoughChars) {
				// When field value length is under X chars

				const cssClasses = [
					styles.paragraph,
				];
				// Empty HTML element
				const missingCharsElement = (
					<li
						className={cssClasses.join(' ')}
						data-testid={`${dataTestid}-notenough`}
						key='autosuggest__missingchars'
					>
						{t('component.input.autosuggest.missingchars', {
							count: APP_CONF_VARS.autosuggest.minNbInputChars
						})}
					</li>
				);

				setSuggestions(undefined);
				setSuggestionsHTML(autosuggestRestrictedElement ? autosuggestRestrictedElement : missingCharsElement);
				setIsLoading(false);
			} else {
				// When field value length is equal or above X chars
				if (autosuggestRestrictedElement) {
					setSuggestionsHTML(autosuggestRestrictedElement);
					setIsLoading(false);
				} else {
					// Execute the created function
					searchWithApi(value);
				}
			}
		} else {
			// When field value is empty
			setSuggestionsHTML(undefined);
			setSuggestions(undefined);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (suggestions?.length && !disabled) {
			setCssClasses([
				...cssClasses,
				styles.results,
			]);
		} else {
			setCssClasses([
				...cssClasses.filter(cssClass => ![
					styles.results,
				].includes(cssClass))
			]);
		}
	}, [
		suggestions,
		disabled
	]);

	useDebouncedEffect(() => {
		handleOnChangeValue(state.value);
	}, [
		state.value
	], APP_CONF_VARS.timeout.debounce);

	useEffect(() => {
		let arrayItems: dataToShowProps[] = [
		];

		if (searchResults?.hits?.data) {
			/* istanbul ignore next */
			if (onDisplayResults) onDisplayResults(searchResults.hits.data as dataToShowProps);

			if (searchResults.hits.data.length) {
				const allowedDataTypes = [
					'building',
					'company',
					'implantation'
				];
				const filteredResults = (searchResults.hits.data as Array<searchProps>)
					.filter((data) => allowedDataTypes.includes(data.data_type))
					.slice(0, APP_CONF_VARS.autosuggest.maxNbResults);

				arrayItems = filteredResults.map((resultSearchItem: searchProps) => {
					// WARNING !
					// dataToShow properties order is important because it affects data order in results display
					let dataToShow: dataToShowProps = {
						link: utils.getURL(resultSearchItem.link),
						type: resultSearchItem.data_type
					};

					switch (resultSearchItem.data_type) {
						case 'building':
							/* istanbul ignore next */
							dataToShow = {
								...dataToShow,
								icon: 'building',
								name: resultSearchItem.name || null,
								address: resultSearchItem.address,
							};
							break;
						case 'company':
							/* istanbul ignore next */
							dataToShow = {
								...dataToShow,
								icon: 'users',
								name: resultSearchItem.name,
								staff: resultSearchItem.staff ? t('general.employee', {
									count: resultSearchItem.staff
								}) : null,
								activity: resultSearchItem.activity,
							};
							break;
						case 'implantation':
							/* istanbul ignore next */
							dataToShow = {
								...dataToShow,
								icon: 'building-people',
								name: resultSearchItem.company.name || null,
								staff: resultSearchItem.staff ? t('general.employee', {
									count: resultSearchItem.staff
								}) : null,
								address: resultSearchItem.address || null,
							};
							break;
					}

					let resultsString: string = null;
					const results: Array<string> = [
					];
					Object.keys(dataToShow).forEach((dataToShowKey: string) => {
						let result = null;

						// TOP AVOID EMPTY VALUES
						if (!dataToShow[dataToShowKey as string]) {
							return;
						}

						switch (dataToShowKey) {
							case 'address':
								result = utilsText.format(dataToShow[dataToShowKey], 'address');
								break;
							case 'link':
							case 'icon':
							case 'type':
								// DO NOTHING HERE WITH THESE PROPERTIES
								break;
							default:
								result = dataToShow[dataToShowKey];
						}
						results.push(result);
					});
					const resultsArray = results.filter((result: string) => result?.length);
					resultsString = resultsArray.join(', ');

					// START HIGHLIGHT PROCESS
					// Clean the query string then split it in array
					const queryStringWords: Array<string> = state.value.replace(/[.,/#!$%^&*;:{}=\-_`~()'"]/g, '').replace(/\s{2,}/g, ' ').split(' ').filter(result => result?.length);
					const resultStringWords: Array<string> = resultsString.replace(/[.,/#!$%^&*;:{}=\-_`~()'"]/g, '').replace(/\s{2,}/g, ' ').split(' ').filter((result: string) => result?.length);

					// Function to split the input string into text and HTML tag chunks
					const splitInput = (str: string) => str.split(/(<\/?b>)/gi).filter(Boolean);

					// Check the levenshtein distance for each results words from query words
					resultStringWords.forEach((resultStringWord: string) => {
						const isClose = utilsText.isWordCloseAtLeastOneWordFromWordlist(resultStringWord, queryStringWords);
						if (isClose) {
							const regExp = new RegExp(resultStringWord, 'gi');
							resultsString = resultsString.replace(regExp, (m: string) => `<b>${m}</b>`);
						}
					});

					// Check the strict correspondance with query words and results words
					queryStringWords.forEach(queryStringWord => {
						// Regular expression to ensure that the word is not inside a non-b HTML tag
						// and not followed by a non-tag character until the next HTML opening tag
						const regex = new RegExp(`(?:^|>)([^<]*?)\\b(${queryStringWord})\\b([^<]*?)(?=<|$)`, 'gi');
						resultsString = splitInput(resultsString).map((chunk: string) => {
							// If the chunk is already inside a <b></b> tag, leave it as is
							/* istanbul ignore next */
							if (chunk.startsWith('<b>') && chunk.endsWith('</b>')) {
								return chunk;
							}
							// If the chunk is text, apply the replacement
							return chunk.replace(regex, (match, before, word, after) => `${before}<b>${word}</b>${after}`);
						}).join('');
					});
					// END HIGHLIGHT PROCESS

					dataToShow = {
						...dataToShow,
						text: resultsString
					};

					return dataToShow;
				});
				setSuggestions(arrayItems);
				setSuggestionsHTML(undefined);
			} else {
				setSuggestionsHTML(noResultsElement);
			}
		}
	}, [
		searchResults
	]);

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={`${dataTestid}-theme-option`}
			ref={ref}
		>
			<div>
				<InputText
					{...otherProps}
					data-testid={`${dataTestid}-search`}
					disabled={disabled}
					hasBorder={hasBorder}
					iconLeft='search'
					iconRight={null}
					initialValue={initialValue as string || defaultValue as string}
					size={size}
					type={EnumInputType.TEXT}
					onChange={handleOnChange}
				/>
				{state.iconIsToggled && !disabled ? iconRightElement : null}
			</div>
			<SuggestionList
				className={styles.panel}
				data-testid={`${dataTestid}-results`}
				hasBorder={hasBorder}
				hasShadow={hasShadow}
				isLoading={isLoading}
				suggestionsHTML={suggestionsHTML}
			>
				{suggestions?.length ? suggestions?.map((suggestion: dataToShowProps, key: number) => (
					<Suggestion
						key={key}
						{...suggestion}
						onClick={(event: MouseEvent<HTMLInputElement>) => {
							if (onClickResult) onClickResult(event, suggestion);
						}}
					/>
				)) : undefined}
			</SuggestionList>
		</div>
	);
};

InputSearchWithDropdown.displayName = EnumComponentType.INPUT_SEARCH_WITH_DROPDOWN;

export {
	InputSearchWithDropdown as default,
	InputSearchWithDropdownProps,
	dataToShowProps,
};
