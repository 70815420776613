import React, {
	useRef,
	useState,
} from 'react';
import {
	useTranslation, withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';
import columnTableConf from './config/columnTable.config';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import Table from '@components/table';
import ContactRow from './contact-row';

// STYLES
import styles from './contacts.module.scss';

const Contacts = () => {
	const { t } = useTranslation();
	const {
		offerState,
		setOfferState,
		isDesktopResolution,
	} : PageOfferContextProps = useOutletContext();

	const [
		contactsIds,
		setContactsIds
	] = useState([
	] as number[]);

	console.log('contactsIds', contactsIds);

	const formRef = useRef(null);

	const noResultElement = ( <p>Pas de résultat</p>);

	const inputsContacts = (
		<Table
			className={styles.table}
			columns={columnTableConf}
			customNoResult={noResultElement}
			data={[
				{
					id: 1,
					contactIdentity: 'John Doe',
					role: 'Manager',
					mandate: 'Project A',
					mandateNumber: '001',
					refOffer: 'Offer123',
					receiptDate: '2024-09-01',
					link: '#',
					comment: 'No comments'
				},
			]}
			identifier='table_demands'
			options={{
				canSelect: true,
				hasHeader: isDesktopResolution,
				pagination: {
					nbItemsPerPage: 10,
					onChange: (event) => {
						console.log(event);
						// handleOnChangePagination(event);
					}
				},
			}}
			// reinitSelection={reinitSelection}
			renderSubComponent={(props) => {
				return  (
					<ContactRow
						{...props}
						// className={styles.contact_row}
						isDesktopResolution={isDesktopResolution}
						// setContactsIds={setContactsIds}
					/>
				);
			}}
			setComponentSelectedIds={setContactsIds}
			// setReinitSelection={setReinitSelection}
			theme='column'
		/>
	);

	return (
		<div className={`${styles.section} ${styles.section__full}`}>
			<div className={styles.step_progress}>
				{t('format.capitalize', {
					text: `${t('page.offers_create_edit.step')} 3/8`
				})}
			</div>
			<div className={styles.legend}>{t('general.form.input.required')}</div>
			<div className={styles.title}>{t('page.offers_create_edit.contacts.who_are_the_contacts')}</div>
			<div className={styles.title}>{t('page.offers_create_edit.contacts.mandate_contact_number')}</div>
			<ValidationForm
				formRef={formRef}
				inputs={inputsContacts}
				isDesktopResolution={isDesktopResolution}
				isValid={offerState ? true : false}
				offerState={offerState}
				setOfferState={setOfferState}
			/>
		</div>
	);
};

export default withTranslation()(Contacts);
