import React, {
	RefObject,
	useEffect,
	useState,
} from 'react';
import {
	useSelector,
} from 'react-redux';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useParams,
} from 'react-router-dom';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// TYPES
import {
	Offer,
	OfferJson,
} from '@@types/Offer';

// ENUMS
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumSteps,
} from '@enums/steps.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';

// ROUTES
import PATHS from '@routes/paths';
import {
	APP_CONF_VARS,
	EnumImageFormatQuery,
} from '@appConf/vars.conf';

// MODULES
import * as utils from '@modules/utils';
import imagesModules from '@modules/images';

// COMPONENTS
import Button from '@components/button';
import Link from '@components/link';
import Sticky from '@components/sticky';
import Text from '@components/text';
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

// STYLES
import styles from './header.module.scss';

interface HeaderProps {
	actualLanguage: string;
	contentMatchingRef: RefObject<HTMLDivElement>;
	isDesktopResolution: boolean;
	offerGlobalState: OfferJson;
}

const Header = ({
	actualLanguage,
	contentMatchingRef,
	offerGlobalState,
	isDesktopResolution,
}: HeaderProps): JSX.Element => {
	const { t } = useTranslation();
	const { step } = useParams();
	const commercializationStepName = step || 'matched';
	const navIsOpen = useSelector((state: ReducerInstance) => state.app.instance.navIsOpen);
	const offer = offerGlobalState ? new Offer(offerGlobalState, actualLanguage) : undefined;
	const titleElement = (
		<div className={styles.title}>
			<h1>
				{t('format.capitalize', {
					text: t('page.matching_offer_demands.title')
				})}
			</h1>
			<Link
				href={`${APP_CONF_VARS.sfLegacyPath}/offer/detail/id/${offer.id}`}
			>
				<Text
					className={styles.ref}
					theme={EnumStatusTheme.INFO}
				>
					{t('format.capitalize', {
						text: t('general.ref', {
							'%ref%': offer.id
						})
					})}
				</Text>
			</Link>
		</div>
	);

	const dataAddress = {
		title: offer.building.address.full,
		image_url: offer?.images?.collection?.length ? imagesModules.updateImageUrls(offer.images.collection[0].url, EnumImageFormatQuery.SQUARE) as string || undefined : undefined,
		descriptions: [
			{
				value: t('format.capitalize', {
					text: t('page.matching_offer_demands.to_commercilize')
				}),
				type: 'string'
			},
		]
	};

	const [
		isSticky,
		setIsSticky
	] = useState(false);

	const [
		stickyCssClasses,
		setStickyCssClasses
	] = useState([
		styles.filter
	]);

	useEffect(() => {
		const baseClasses = [
			styles.filter
		];
		if (isSticky) {
			baseClasses.push(styles.filter__sticky);
			if (navIsOpen && isDesktopResolution) {
				baseClasses.push(styles.filter__sticky__navisopen);
			}
		}
		setStickyCssClasses(baseClasses);
	}, [
		navIsOpen,
		isSticky
	]);

	const stepListElement = (
		<Sticky
			className={stickyCssClasses.join(' ')}
			isDesktopResolution={true}
			isSticky={isSticky}
			scrollContainerRef={isDesktopResolution ? contentMatchingRef : undefined}
			setIsSticky={setIsSticky}
		>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Default}
				counter={offerGlobalState?.counters?.steps[EnumSteps.MATCHED]}
				iconLeft={'bullseye-pointer'}
				iconStyle={EnumFontStyle.REGULAR}
				isActive={commercializationStepName === EnumSteps.MATCHED}
				label={t('format.capitalize', {
					text: t('page.matching_offer_demands.steps.matched.title'),
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={utils.getURL(PATHS.OFFERS.MATCHING_DEMANDS, {
					':offerid': `${offer.id}`,
					':step': EnumSteps.MATCHED
				})}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Default}
				counter={offerGlobalState?.counters?.steps[EnumSteps.PROPOSED]}
				iconLeft={'share-arrow'}
				iconStyle={EnumFontStyle.REGULAR}
				isActive={commercializationStepName === EnumSteps.PROPOSED}
				label={t('format.capitalize', {
					text: t('page.matching_offer_demands.steps.proposed.title'),
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={utils.getURL(PATHS.OFFERS.MATCHING_DEMANDS, {
					':offerid': `${offer.id}`,
					':step': EnumSteps.PROPOSED
				})}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Default}
				counter={offerGlobalState?.counters?.steps[EnumSteps.APPLIED]}
				iconLeft={'folder'}
				iconStyle={EnumFontStyle.REGULAR}
				isActive={commercializationStepName === EnumSteps.APPLIED}
				label={t('format.capitalize', {
					text: t('page.matching_offer_demands.steps.applied.title'),
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={utils.getURL(PATHS.OFFERS.MATCHING_DEMANDS, {
					':offerid': `${offer.id}`,
					':step': EnumSteps.APPLIED
				})}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Default}
				counter={offerGlobalState?.counters?.steps[EnumSteps.REFUSED]}
				iconLeft={'times'}
				iconStyle={EnumFontStyle.REGULAR}
				isActive={commercializationStepName === EnumSteps.REFUSED}
				label={t('format.capitalize', {
					text: t('page.matching_offer_demands.steps.refused.title'),
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={utils.getURL(PATHS.OFFERS.MATCHING_DEMANDS, {
					':offerid': `${offer.id}`,
					':step': EnumSteps.REFUSED
				})}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Default}
				counter={offerGlobalState?.counters?.steps[EnumSteps.IGNORED]}
				iconLeft={'trash-alt'}
				iconStyle={EnumFontStyle.REGULAR}
				isActive={commercializationStepName === EnumSteps.IGNORED}
				label={t('format.capitalize', {
					text: t('page.matching_offer_demands.steps.ignored.title'),
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={utils.getURL(PATHS.OFFERS.MATCHING_DEMANDS, {
					':offerid': `${offer.id}`,
					':step': EnumSteps.IGNORED
				})}
			/>
		</Sticky>
	);

	return (
		<div className={styles.header}>
			{titleElement}
			<UserCard
				className={styles.user_card}
				data={dataAddress}
				hasEllipsis={isDesktopResolution ? false : true}
				theme={ThemeUserCard.SQUARE}
			/>
			{stepListElement}
		</div>
	);
};
// };

export default withTranslation()(Header);
