import React, {
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumQueryMethods,
} from '@enums/query.enum';

// HOOKS
import useClickOutsideEffect from '@hooks/useClickOutsideEffect/hook.useClickOutsideEffect';

// MODULES
import utilsText from '@modules/text';

// LAYOUTS
import {
	AddressesProps,
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';
import {
	getAdresses,
} from '@layouts/Pages/PageOfferEntry/config/fetch';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import Image from '@components/image';
import InputText from '@components/form/input-text';
import Modal, {
	ModalSize,
} from '@components/modal';
import InputRadioGroup from '@components/form/input-radio-group';
import Icon from '@components/icon';
import SuggestionList from '@components/suggestion-list';
import Suggestion from '@components/suggestion-list/suggestion';
import StatusBanner from '@components/status-banner';

// ASSETS
import locationImage from '@assets/images/lpdipro/illu_men_location.png';

// STYLES
import styles from './location.module.scss';

const Location = () => {
	const {t} = useTranslation();
	const {
		offerState,
		setOfferState,
		isDesktopResolution,
	}: PageOfferContextProps = useOutletContext();

	const [
		addresses,
		setAddresses
	] = useState(undefined);

	const onSubmitSuccess = (data: Record<string, undefined>) => {
		setIsLoadingSuggestions(true);
		getAdresses(data.street_raw, data.city_raw).then(responseParsed => {
			if (responseParsed.status === 200) {
				const formatedAddresses = responseParsed.payload?.length ? responseParsed.payload.map((addressRequest: Record<string, unknown>) => {
					return {
						icon: addressRequest?.id ? 'building' : 'map-marker-alt' ,
						type: addressRequest?.id ? 'building' : 'address',
						id: addressRequest?.id,
						decoded: addressRequest?.decoded,
						is_main: offerState?.addresses?.length ? false : true,
						is_valid: addressRequest?.is_valid,
						street_raw: addressRequest?.street_raw,
					};
				}) : [
				];

				const filteredAddresses = formatedAddresses?.length ? formatedAddresses.filter((formattedAddress: Record<string, string>) => !offerState?.addresses?.some(offerAddress => utilsText.normalizeText(offerAddress.street_raw) === utilsText.normalizeText(formattedAddress.street_raw))) : [
				];

				setAddresses(filteredAddresses);
			} else {
				setAddresses([
				]);
			}
		}).catch(() => {
			setAddresses([
			]);
		}).finally(() => {
			setIsLoadingSuggestions(false);
		});
	};

	const refAddressesForm = useRef();

	const handleOnClickOutside = () => {
		setAddresses(null);
	};

	useClickOutsideEffect([
		refAddressesForm
	], handleOnClickOutside);

	const [
		isOpen,
		setIsOpen
	] = useState(false);

	const [
		customAddressState,
		setCustomAddressState
	] = useState({
		street_raw: undefined,
		pretty_address: undefined,
		isOpen: false
	});

	const [
		isLoadingSuggestions,
		setIsLoadingSuggestions
	] = useState(false);

	const formCustomAddressRef = useRef(null);

	const handleOnClickCancel = ((event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setCustomAddressState({
			...customAddressState,
			isOpen: false
		});
	});

	const modalCustomAddress = customAddressState.isOpen ? (
		<Modal
			hasHeader={true}
			isDesktopResolution={isDesktopResolution}
			isOpen={customAddressState.isOpen}
			size={ModalSize.DEFAULT}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.location.custom_address_offer')
			})}
			onClose={() => {
				setCustomAddressState({
					...customAddressState,
					isOpen: false
				});
			}}
		>
			<Form
				className={styles.form__modal_address}
				innerRef={formCustomAddressRef}
				method={EnumQueryMethods.GET}
				onSuccess={(data: Record<string, string>) => {
					const addressesArray = [
					] as AddressesProps[];

					if (offerState?.addresses?.length) {
						offerState?.addresses.forEach(address => {
							if (address.street_raw === customAddressState.street_raw) {
								addressesArray.push({
									...address,
									pretty_address: data.pretty_address
								});
								setCustomAddressState({
									...customAddressState,
									isOpen: false
								});
							} else {
								addressesArray.push(address);
							}
						});
						setOfferState({
							...offerState,
							addresses: [
								...addressesArray,
							]
						});
					}
				}}
			>
				<InputText
					autoComplete='pretty_address'
					className={styles.input}
					defaultValue={customAddressState.pretty_address}
					label={t('format.capitalize', {
						text: t('general.form.input.type.street.label')
					})}
					name='pretty_address'
					placeholder={t('format.capitalize', {
						text: t('general.form.input.type.street.placeholder')
					})}
					required={true}
					type={EnumInputType.TEXT}
				/>

				<div className={styles.footer_buttons}>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.cancel')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						onClick={handleOnClickCancel}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.confirm')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						type={EnumButtonType.SUBMIT}
					/>
				</div>
			</Form>
		</Modal>
	) : undefined;

	const noResultsElement = (
		<li
			className={styles.noresults_element}
		>
			<Icon
				className={styles}
				fontStyle={EnumFontStyle.LIGHT}
				name='search-slash'
			/>
			<span>{t('page.offers_create_edit.location.no_results_address')}</span>
		</li>
	);

	const suggestionElement = (
		<SuggestionList
			className={styles.addresses_suggestions}
			hasBorder={isDesktopResolution ? true : false}
			hasShadow={isDesktopResolution ? true : false}
			isLoading={isLoadingSuggestions}
			suggestionsHTML={addresses?.length === 0 ? noResultsElement : undefined}
		>
			{addresses?.map((address: AddressesProps, key: number) => {
				return address ? (
					<div key={key}>
						<Suggestion
							icon={address.icon}
							text={address.street_raw}
							type={address.type}
							onClick={(event) => {
								event.preventDefault();

								const addressesArray = [
								] as AddressesProps[];
								if (offerState?.addresses?.length) {
									offerState?.addresses.forEach(address => {
										addressesArray.push(address);
									});
								}
								setOfferState({
									...offerState,
									addresses: [
										...addressesArray,
										{
											decoded: address.decoded,
											id: address.id,
											is_main: address.is_main,
											street_raw: address.street_raw,
										} as AddressesProps,
									]
								});
								setAddresses(undefined);
								if (!isDesktopResolution) {
									setIsOpen(false);
								}
							}}
						/>
						{address.is_valid ? null : (
							<StatusBanner
								className={styles.invalid_postal_address}
								message={t('format.capitalize', {
									text: t('page.offers_create_edit.location.invalid_postal_address')
								})}
								theme={EnumStatusTheme.WARNING}
							/>
						)}
					</div>
				) : undefined;
			})}
		</SuggestionList>
	);

	const optionList = offerState?.addresses?.length ?
		(offerState.addresses).map((address, index) => {
			const choiceId = `address_${index}`;

			const customAddressTranslation = address?.pretty_address ? t('general.customized', {
				context: 'female',
			}) : '';
			const labelRadio = (
				<div
					className={styles.addresses_choice}
				>
					<span>{address?.pretty_address ? `${address.pretty_address} (${customAddressTranslation})` : address.street_raw}</span>
					<Button
						corners={EnumButtonCorners.Square}
						hasBoxShadow={false}
						iconLeft='edit'
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.NAKED}
						type={EnumButtonType.BUTTON}
						onClick={() => {
							setCustomAddressState({
								street_raw: address.street_raw,
								pretty_address: address?.pretty_address || address.street_raw,
								isOpen: true
							});
						}}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						hasBoxShadow={false}
						iconLeft='trash-alt'
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.NAKED}
						type={EnumButtonType.BUTTON}
						onClick={() => {
							let formatedAddresses = offerState?.addresses.filter((_, addrIndex) => addrIndex !== index);

							const hasMainAddress = formatedAddresses.some((address) => address.is_main);

							if (!hasMainAddress && formatedAddresses.length > 0) {
								formatedAddresses = formatedAddresses.map((address, addrIndex) => {
									return ({
										...address,
										is_main: addrIndex === 0 ? true : address.is_main,
									});
								});
							}

							setOfferState({
								...offerState,
								addresses: formatedAddresses,
							});
						}}
					/>
				</div>
			);

			return {
				className: styles.input_choice,
				label: labelRadio,
				name: 'main_address',
				key: index,
				id: choiceId,
				value: JSON.stringify(address),
				checked: address.is_main,
			};

		}) : undefined;

	const indexOfMainAddress = offerState?.addresses?.findIndex(address => address.is_main === true);

	const inputRadioGroup = offerState?.addresses?.length ? (
		<div
			className={styles.input_radio_group}
		>
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.location.main_address_offer')
				})}
			</div>
			<InputRadioGroup
				name="choice_address"
				options={optionList}
				selectedIndex={indexOfMainAddress !== -1 ? indexOfMainAddress : 0}
				onChange={(event) => {
					const mainAddress = JSON.parse(event.target.value);

					const formatedAddresses = offerState?.addresses.map((address: AddressesProps) => {
						return {
							decoded: address.decoded,
							id: address.id,
							is_main: mainAddress.street_raw === address.street_raw,
							street_raw: address.street_raw,
							pretty_address: address?.pretty_address,
						};
					});

					setOfferState({
						...offerState,
						addresses: [
							...formatedAddresses,
						]
					});
				}}
			/>
		</div>
	) : undefined;

	const formRef = useRef(null);

	return (
		<div className={styles.section}>
			<div className={styles.section__left}>
				<div className={styles.step_progress}>
					{t('format.capitalize', {
						text: `${t('page.offers_create_edit.step')} 1/8`
					})}
				</div>
				<div className={styles.legend}>{t('general.form.input.required')}</div>
				<div className={styles.title}>
					{t('format.capitalize', {
						text: t('page.offers_create_edit.location.where_is_your_offer')
					})} {<span>*</span>}
				</div>
				{isDesktopResolution ? (
					<div
						className={styles.container}
						ref={refAddressesForm}
					>
						<Form
							className={styles.form}
							innerRef={formRef}
							method={EnumQueryMethods.GET}
							onSuccess={onSubmitSuccess}
						>
							<InputText
								autoComplete='street-address'
								className={styles.input}
								customError={t('format.capitalize', {
									text: t('general.form.input.error.address')
								})}
								label={t('format.capitalize', {
									text: t('general.form.input.type.street.label')
								})}
								name='street_raw'
								placeholder={t('format.capitalize', {
									text: t('general.form.input.type.street.placeholder')
								})}
								required={true}
								type={EnumInputType.TEXT}
							/>
							<InputText
								autoComplete='street-address'
								className={styles.input}
								customError={t('format.capitalize', {
									text: t('general.form.input.error.city')
								})}
								label={t('format.capitalize', {
									text: t('general.form.input.type.city.label')
								})}
								name='city_raw'
								placeholder={t('format.capitalize', {
									text: t('general.form.input.type.city.placeholder')
								})}
								required={true}
								type={EnumInputType.TEXT}
							/>
							<Button
								className={styles.button}
								corners={EnumButtonCorners.Square}
								iconLeft='search'
								type={EnumButtonType.SUBMIT}
							/>
						</Form>
						{suggestionElement}
					</div>
				) : (
					<Button
						className={styles.trigger_modal}
						corners={EnumButtonCorners.Square}
						hasBoxShadow={false}
						iconLeft='map-marker-alt'
						iconStyle={EnumFontStyle.LIGHT}
						label={t('format.capitalize', {
							text: t('general.form.input.type.address.placeholder')
						})}
						theme={EnumTheme.SECONDARY}
						type={EnumButtonType.BUTTON}
						onClick={() => {
							setIsOpen(true);
						}}
					/>
				)}
				<ValidationForm
					formRef={formRef}
					inputs={inputRadioGroup}
					isDesktopResolution={isDesktopResolution}
					isValid={offerState?.addresses?.length ? true : false}
					offerState={offerState}
					setIsOpen={isDesktopResolution ? undefined : setIsOpen}
					setOfferState={setOfferState}
				/>
			</div>
			{isDesktopResolution ? (
				<div className={styles.section__right}>
					<Image
						alt='placeholder'
						className={styles.image}
						src={locationImage}
					/>
				</div>
			) : (
				<Modal
					hasHeader={true}
					isDesktopResolution={false}
					isOpen={isOpen}
					size={ModalSize.FULL}
					title={t('format.capitalize', {
						text: t('page.offers_create_edit.location.offer_address')
					})}
					onClose={() => {
						setIsOpen(false);
					}}
				>
					<Form
						className={styles.form__modal}
						innerRef={formRef}
						method={EnumQueryMethods.GET}
						onSuccess={onSubmitSuccess}
					>
						<InputText
							autoComplete='street-address'
							className={styles.input}
							customError={t('format.capitalize', {
								text: t('general.form.input.error.address')
							})}
							label={t('format.capitalize', {
								text: t('general.form.input.type.street.label')
							})}
							name='street_raw'
							placeholder={t('format.capitalize', {
								text: t('general.form.input.type.street.placeholder')
							})}
							required={true}
							type={EnumInputType.TEXT}
						/>
						<div
							className={styles.input_group}
							ref={refAddressesForm}
						>
							<InputText
								autoComplete='street-address'
								className={styles.input}
								customError={t('format.capitalize', {
									text: t('general.form.input.error.city')
								})}
								label={t('format.capitalize', {
									text: t('general.form.input.type.city.label')
								})}
								name='city_raw'
								placeholder={t('format.capitalize', {
									text: t('general.form.input.type.city.placeholder')
								})}
								required={true}
								type={EnumInputType.TEXT}
							/>
							<Button
								className={styles.button}
								corners={EnumButtonCorners.Square}
								iconLeft='search'
								type={EnumButtonType.SUBMIT}
							/>
							{suggestionElement}
						</div>
					</Form>
				</Modal>
			)}
			{modalCustomAddress}
		</div>
	);
};

export default withTranslation()(Location);
