// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FTUXgZi8{background-color:var(--color-background-default);box-shadow:0.07142857rem 0.07142857rem 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.2),-0.07142857rem -0.07142857rem 0.35714286rem 0 hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.2);position:relative;z-index:5}.FTUXgZi8 .sIY2_Zmp{align-items:flex-start;display:flex;flex-direction:column;padding:1.14285714rem 1.14285714rem 0}@media screen and (min-width: 768Px)and (orientation: landscape){.FTUXgZi8 .sIY2_Zmp{align-items:center;flex-direction:row;padding:1.42857143rem 1.42857143rem 0}}.FTUXgZi8 .sIY2_Zmp>h1{margin-bottom:0;padding:0}.FTUXgZi8 .sIY2_Zmp .k_tU_4yF{font-size:0.85714286rem;font-weight:600;margin:0.28571429rem 0 0}@media screen and (min-width: 768Px)and (orientation: landscape){.FTUXgZi8 .sIY2_Zmp .k_tU_4yF{margin:0 0 0 1.07142857rem}}._51ag1IZ_{display:flex;justify-content:space-between;left:0;overflow:auto;padding:1.14285714rem;position:relative;right:0;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){._51ag1IZ_{padding:1.42857143rem}}.cr_e3kSG{background-color:var(--color-background-default);box-shadow:0.07142857rem 0.07142857rem 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.2),-0.07142857rem -0.07142857rem 0.35714286rem 0 hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.2);left:0;margin-top:0;padding:1.14285714rem;position:fixed;right:0;top:0;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.cr_e3kSG{left:4rem;padding:1.42857143rem;top:4.57142857rem;width:calc(100% - 4rem)}}.rCXwomwl{left:18.92857143rem;width:calc(100% - 18.92857143rem)}.CoHaouni{white-space:nowrap}.TWna1D7U{opacity:.3}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `FTUXgZi8`,
	"title": `sIY2_Zmp`,
	"ref": `k_tU_4yF`,
	"filter": `_51ag1IZ_`,
	"filter__sticky": `cr_e3kSG`,
	"filter__sticky__navisopen": `rCXwomwl`,
	"step": `CoHaouni`,
	"loader": `TWna1D7U`
};
export default ___CSS_LOADER_EXPORT___;
