import React, {
	ChangeEvent,
	useState,
	useEffect,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// COMPONENTS
import InputRadio, {
	InputRadioProps,
} from '@components/form/input-radio-group/input-radio';
import {
	InputProps,
} from '@components/form/input';

// ENUMS
import {
	EnumInputPosition,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';

// STYLING
import styles from './input-radio-group.module.scss';

interface InputRadioGroupProps extends Omit<InputProps, 'hasBorder' | 'hasShadow'> {
	options: Record<string, unknown>[];
	selectedIndex?: number;
}

const InputRadioGroup = ({
	className,
	customError,
	'data-testid': dataTestid,
	disabled = false,
	inputPosition = EnumInputPosition.LEFT,
	invalid,
	onChange,
	options,
	methods,
	name,
	required,
	selectedIndex = 0,
	validationType,
}: InputRadioGroupProps): JSX.Element => {

	const { t } = useTranslation();
	const isControlled = methods?.register;
	const registerRules: InputProps['registerRules'] = {
	};

	useEffect(() => {
		// When the component mounts or selectedIndex changes, update the value in the form
		if (methods && options[selectedIndex]?.value) {
			methods.setValue(name, options[selectedIndex]?.value);
		}
	}, [
		selectedIndex,
		methods,
		name,
		options
	]);

	// Register the radio group with React Hook Form if controlled
	if (isControlled) {
		if (validationType && required) {
			registerRules.required = {
				value: true,
				message: t('general.form.input.error.radio')
			};
		}

		methods?.register(name, registerRules);
	}

	const error = invalid ? {
		message: customError || methods?.formState?.errors[name],
	} : {
	};

	const classes = [
		styles.input_radio_group,
		styles[`position__${inputPosition}`]
	];

	if (className) classes.push(className);
	if (error?.message || invalid) classes.push('invalid');

	const [
		state,
		setState
	] = useState({
		selectedIndex: selectedIndex,
		disabled: disabled,
	});

	function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
		const InputHtml = event.target;
		let currentIndex = state.selectedIndex;

		options.forEach((item: InputRadioProps, index) => {
			if (item.id === InputHtml.id && item.disabled !== true && !state.disabled) {
				currentIndex = index;
			}
		});

		setState({
			...state,
			selectedIndex: currentIndex,
		});

		// Update the form value
		if (methods) {
			methods.setValue(name, options[currentIndex]?.value);
			methods.clearErrors(name);
		}

		if (onChange) onChange(event, {
			...state,
			selectedIndex: currentIndex,
		});
	}

	const errorMsgElement = error?.message ? (
		<div
			className={styles.error}
			data-error
			data-testid={`${dataTestid}-error`}
			role="alert"
		>
			{error.message}
		</div>
	) : null;

	function formatFields(fieldsToFormat: InputRadioGroupProps['options']) {
		return fieldsToFormat.map((field: InputRadioProps, index: number) => {
			const inputClasses = [
				styles.input_radio_group__input,
			];

			if (field.className) inputClasses.push(field.className);

			return (
				<InputRadio
					{...field}
					checked={state.selectedIndex !== null && state.selectedIndex === index}
					className={inputClasses.join(' ')}
					data-testid={ `${dataTestid}-radio-group`}
					disabled={state.disabled || field.disabled}
					key={index}
					onChange={handleOnChange}
				/>
			);
		});
	}

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			{formatFields(options)}
			{error?.message || invalid ? errorMsgElement : null}
		</div>
	);
};

InputRadioGroup.displayName = EnumComponentType.INPUT_RADIO_GROUP;

export {
	InputRadioGroup as default,
	InputRadioGroupProps,
};
