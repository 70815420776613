
import React from 'react';
import PATHS from '@routes/paths';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	useNavigate,
} from 'react-router-dom';

// STORES
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// LAYOUTS
import Page from '@layouts/Pages/Page';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Button, {
} from '@components/button';
import Form, {
	ResponseParsedProps,
} from '@components/form';
import StatusBanner from '@components/status-banner';

// ROUTES
import FETCHES from '@routes/fetches';

// STYLES
import styles from './step-password-edit.module.scss';
import {
	EnumInputType,
} from '@enums/form.enum';
import InputPassword from '@components/form/input-password';
import InputPasswordWithValidation from '@components/form/input-password-with-validation';
import InputHidden from '@components/form/input-hidden';

function PagePasswordEdit() {
	const { t } = useTranslation();
	const dispatch = useDispatch(); // Dispatches an action. This is the only way to trigger a state change.
	const navigate = useNavigate();
	const statusMsgs = useSelector((state: ReducerInstance) => state.status_msgs.instances);

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const token = urlParams.get('token');

	//event triggered when the form is submitted
	const handleOnError = (response: ResponseParsedProps) => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());

		switch (response.statusText) {
			case 'error_reset_password_expired_token': {
				dispatch(addStatusMsg({
					message: t('status.error_reset_password_expired_token'),
					theme: EnumStatusTheme.ERROR,
				}));
				navigate(PATHS.LOGIN._ROOT);
				break;
			}
			case 'error_reset_password_limit_exceeded': {
				dispatch(addStatusMsg({
					message: t('status.error_reset_password_limit_exceeded'),
					theme: EnumStatusTheme.ERROR,
				}));
				navigate(PATHS.LOGIN._ROOT);
				break;
			}
			case 'error_reset_password_not_found_token': {
				dispatch(addStatusMsg({
					message: t('status.error_reset_password_not_found_token'),
					theme: EnumStatusTheme.ERROR,
				}));
				navigate(PATHS.LOGIN._ROOT);
				break;
			}
			default:
				// Manage error message
				if (response.statusText) {
					dispatch(addStatusMsg({
						message: t([
							`status.${response.statusText}`,
							'status.default'
						]),
						theme: EnumStatusTheme.ERROR,
					}));
				}
		}
	};

	const handleOnSuccess = (responseParsed: ResponseParsedProps) => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		if (responseParsed.statusText === 'success') {

			// Navigate to success password recovery
			navigate(PATHS.LOGIN.PASSWORD_SUCCESS);
		}
	};

	return (
		<Page
			className={`${styles.step} wrapper__small`}
			pageName='login'
			step='password_edit'
		>
			{statusMsgs.length ? (
				<div
					className={styles.statusMsgWrapper}
					data-status-msg-wrapper
				>
					{statusMsgs.map((statusBanner, index) => {
						return (
							<StatusBanner
								key={index}
								message={statusBanner.message}
								theme={statusBanner.theme}
							/>
						);
					})}
				</div>
			) : ''}
			<div className={`${styles.main}`}>
				<Form
					action={FETCHES.public.authentification.reset_password_token}
					onError={(responseParsed) => {
						handleOnError(responseParsed as ResponseParsedProps);
					}}
					onSuccess={(responseParsed) => {
						handleOnSuccess(responseParsed as ResponseParsedProps);
					}}
				>
					<InputPasswordWithValidation
						autoComplete='new-password'
						autoFocus={true}
						id='reset_password__new_password'
						label={t('general.form.input.type.password.label')}
						name="reset_password[_new_password]"
						placeholder={t('general.form.input.type.password.new.placeholder')}
						required={true}
						type={EnumInputType.PASSWORD}
					/>
					<InputPassword
						autoComplete='current-password'
						data-check-value='reset_password__new_password'
						id='reset_password__confirmation_new_password'
						label={t('general.form.input.type.password.label')}
						name="reset_password[_confirmation_new_password]"
						placeholder={t('general.form.input.type.password.confirmation.placeholder')}
						required={true}
						type={EnumInputType.PASSWORD}
					/>
					<InputHidden
						defaultValue={token}
						id='reset_password__token'
						name="reset_password[_token]"
						required={true}
					/>
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.send')
						})}
						type={EnumButtonType.SUBMIT}
					/>
				</Form>
			</div>
		</Page>
	);
}

export default withTranslation()(PagePasswordEdit);
