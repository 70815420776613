import React from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';

// STYLES
import styles from './summary.module.scss';

const Summary = () => {
	const { t } = useTranslation();

	return (
		<div className={`${styles.section} ${styles.section__full}`}>
			<div className={styles.step_progress}>
				{t('format.capitalize', {
					text: `${t('page.offers_create_edit.step')} 8/8`
				})}
			</div>
		</div>
	);
};

export default withTranslation()(Summary);
