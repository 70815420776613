// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g8KXaao_{display:flex;flex-direction:row;padding:1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.g8KXaao_{padding:1.42857143rem}}.DOFGqJhZ{display:flex;flex-direction:column;width:100%}.DOFGqJhZ .DyDyVKLB{color:var(--color-primary);margin-bottom:1.42857143rem;font-weight:600;font-size:1rem}.DOFGqJhZ .eLYe44sz{margin-bottom:1.42857143rem;font-size:0.85714286rem}.DOFGqJhZ .OUOGnysP{margin-bottom:1.42857143rem;font-size:1.14285714rem;font-weight:600;line-height:1.6em}.DOFGqJhZ .OUOGnysP:first-letter{display:block;text-transform:uppercase}.spOoa903{color:var(--color-boulder)}.xXzDQPGL{margin-bottom:1.14285714rem;padding:0 0.85714286rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `g8KXaao_`,
	"section__full": `DOFGqJhZ`,
	"step_progress": `DyDyVKLB`,
	"legend": `eLYe44sz`,
	"title": `OUOGnysP`,
	"grey": `spOoa903`,
	"line": `xXzDQPGL`
};
export default ___CSS_LOADER_EXPORT___;
