import * as React from 'react';
import {
	lazy,
} from 'react';
import {
	Navigate,
	useParams,
} from 'react-router-dom';

import PATHS from '@routes/paths';
import {
	getURL,
} from '@modules/utils';

// LAZY LOADING
const Location = lazy(() => import('@layouts/Pages/PageOfferEntry/location'));
const Contacts = lazy(() => import('@layouts/Pages/PageOfferEntry/contacts'));
const Infos = lazy(() => import('@layouts/Pages/PageOfferEntry/infos'));
const Lots = lazy(() => import('@layouts/Pages/PageOfferEntry/lots'));
const Media = lazy(() => import('@layouts/Pages/PageOfferEntry/media'));
const Services = lazy(() => import('@layouts/Pages/PageOfferEntry/services'));
const Summary = lazy(() => import('@layouts/Pages/PageOfferEntry/summary'));
const Terms = lazy(() => import('@layouts/Pages/PageOfferEntry/terms'));

function OfferEditRedirect() {
	const { offerid } = useParams();
	return (
		<Navigate
			to={getURL(PATHS.OFFERS.EDIT.LOCATION, {
				':offerid': offerid,
			})}
		/>
	);
}

const OFFER_ENTRY_LOCATION_CREATE_ROUTES = [
	{
		path: PATHS.OFFERS.NEW,
		element: <Location />,
	},
];

const OFFER_ENTRY_LOCATION_ROUTES = [
	...OFFER_ENTRY_LOCATION_CREATE_ROUTES,
	{
		path: PATHS.OFFERS.EDIT.LOCATION,
		element: <Location />,
	},
];

const OFFER_ENTRY_INFOS_ROUTES = [
	{
		path: PATHS.OFFERS.EDIT.INFOS,
		element: <Infos />,
	},
];

const OFFER_ENTRY_CONTACTS_ROUTES = [
	{
		path: PATHS.OFFERS.EDIT.CONTACTS,
		element: <Contacts />,
	},
];

const OFFER_ENTRY_LOTS_ROUTES = [
	{
		path: PATHS.OFFERS.EDIT.LOTS,
		element: <Lots />,
	},
];

const OFFER_ENTRY_MEDIA_ROUTES = [
	{
		path: PATHS.OFFERS.EDIT.MEDIA,
		element: <Media />,
	},
];

const OFFER_ENTRY_SERVICES_ROUTES = [
	{
		path: PATHS.OFFERS.EDIT.SERVICES,
		element: <Services />,
	},
];

const OFFER_ENTRY_TERMS_ROUTES = [
	{
		path: PATHS.OFFERS.EDIT.TERMS,
		element: <Terms />,
	},
];

const OFFER_ENTRY_SUMMARY_ROUTES = [
	{
		path: PATHS.OFFERS.EDIT.SUMMARY,
		element: <Summary />,
	},
];

const OFFER_ENTRY_ROUTES_REDIRECT = [
	{
		element: <OfferEditRedirect />,
		path: `${PATHS.OFFERS.EDIT._ROOT}/*`,
	},
];

const OFFER_ENTRY_ROUTES = [
	...OFFER_ENTRY_LOCATION_ROUTES,
	...OFFER_ENTRY_INFOS_ROUTES,
	...OFFER_ENTRY_CONTACTS_ROUTES,
	...OFFER_ENTRY_LOTS_ROUTES,
	...OFFER_ENTRY_MEDIA_ROUTES,
	...OFFER_ENTRY_SERVICES_ROUTES,
	...OFFER_ENTRY_TERMS_ROUTES,
	...OFFER_ENTRY_SUMMARY_ROUTES,
	...OFFER_ENTRY_ROUTES_REDIRECT,
];

export {
	OFFER_ENTRY_CONTACTS_ROUTES,
	OFFER_ENTRY_INFOS_ROUTES,
	OFFER_ENTRY_LOCATION_CREATE_ROUTES,
	OFFER_ENTRY_LOCATION_ROUTES,
	OFFER_ENTRY_LOTS_ROUTES,
	OFFER_ENTRY_MEDIA_ROUTES,
	OFFER_ENTRY_ROUTES as default,
	OFFER_ENTRY_SERVICES_ROUTES,
	OFFER_ENTRY_SUMMARY_ROUTES,
	OFFER_ENTRY_TERMS_ROUTES,
};
