import * as React from 'react';
import {
	MouseEvent,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useNavigate,
} from 'react-router-dom';

// TYPES
import {
	DemandJsonPrivate,
} from '@@types/Demand';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// EXECPTIONS
import GetDemandsByBrokerError from '@exceptions/GetDemandsByBrokerError';

// ROUTES
import FETCHES from '@routes/fetches';
import PATHS from '@routes/paths';

// MODULES
import * as utils from '@modules/utils';

// DATA
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import columnTableConf from './config/columnTable.config';
import {
	OnClickActionProps,
} from '@layouts/Pages/PageDemand/config/demand.data';

// STORES
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import {
	userLogout,
} from '@stores/_slices/user';

// SLICES
import {
	clearDemands,
	demandsSetList,
} from '@stores/_slices/demands';
import {
	DeviceDisplays,
	DeviceOrientations,
} from '@stores/_slices/device';

// MODULES
import dataUtils from '@modules/data-utils';
import downloadCSVFile from '@modules/downloadCSVFile';

// LAYOUTS
import ContentLoader from '@layouts/Pages/PageDemands/contentLoader';
import DemandRow from '@layouts/Pages/PageDemands/demand-row';
import ModalUpdateDemandState from '@layouts/Modals/ModalUpdateDemandState';
import ModalDemandsFilterMobile from '@layouts/Modals/ModalDemandsFiltersMobile';
import ModalDeleteDemand from '@layouts/Modals/ModalDeleteDemand';
import Page from '@layouts/Pages/Page';
import Header from '@layouts/Pages/PageDemands/header';

// COMPONENTS
import Button from '@components/button';
import Image from '@components/image';
import Modal, {
	ModalSize,
} from '@components/modal';
import Paragraph from '@components/paragraph';
import Table from '@components/table';

// STYLES
import styles from './PageDemands.module.scss';

// IMAGES
import noResult from '@assets/images/lpdipro/icon_refused.jpg';
import noResultFilter from '@assets/images/lpdipro/icon_search.jpg';

interface SelectedType {
	[key: string]: string[] | string | number;
	activities: string;
	broker: string;
	state: string[];
	counter: number;
}

function PageDemands() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP || (device.display === DeviceDisplays.TABLET && device.orientation === DeviceOrientations.LANDSCAPE);

	const demandsGlobalState = useSelector((state: ReducerInstance) => state.demands.instance);
	const currentUserId = useSelector((state: ReducerInstance) => state.user.instance.id);

	const [
		isContentLoaded,
		setIsContentLoaded
	] = useState(false);

	const [
		isOpenUpdateDemandStateModal,
		setIsOpenUpdateDemandStateModal
	] = useState(false);

	const [
		isOpenDeleteDemandModal,
		setIsOpenDeleteDemandModal
	] = useState(false);

	const [
		hasUsedFilter,
		setHasUsedFilter
	] = useState(false);

	const [
		demandIds,
		setDemandIds
	] = useState([
	] as number[]);

	const [
		reinitSelection,
		setReinitSelection
	] = useState(false);

	const [
		demandModalState,
		setDemandModalState
	] = useState(null as DemandJsonPrivate);

	const [
		isOpenFilterModal,
		setIsOpenFilterModal
	] = useState(false);

	const [
		selected,
		setSelected
	] = useState({
		activities: '',
		broker: `${currentUserId}`,
		state: [
			'active'
		],
		counter: 2
	} as SelectedType);

	useEffect(() => {
		const controller = new AbortController();
		getDemandsByFilter(selected.broker as string, selected.state, selected.activities as string, controller);
		return () => controller.abort();
	}, [
		selected.activities,
		selected.broker,
		selected.state,
	]);

	const getDemandsByFilter = (brokerIds?: string, stateIds?: string[], activitiesIds?: string, controller?: AbortController) => {
		dispatch(clearToastMsgs());
		setIsContentLoaded(false);
		const filters = [
		];

		if (activitiesIds) filters.push('activities=in::activities');
		if (brokerIds) filters.push('filter_brokers=in::brokerids');
		if (stateIds?.length) filters.push('state=in::state');

		const fetchUrl = filters.length ? utils.getURL(`${FETCHES.private.demands._ROOT}?${filters.join('&')}`, {
			':activities': `${activitiesIds}`,
			':brokerids': `${brokerIds}`,
			':state': `${stateIds}`,
		}) : utils.getURL(FETCHES.private.demands._ROOT);

		fetch(fetchUrl, {
			...APP_CONF_VARS.request.default,
			signal: controller ? controller.signal : undefined,
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			if (responseParsed?.payload?.redirect_url) {
				// REDIRECT ASKED BY BACKEND
				if (responseParsed.statusText) {
					dispatch(addToastMsg({
						message: t(`status.${responseParsed.statusText}`),
						theme: EnumStatusTheme.ERROR,
					}));
				}
				window.location.href = responseParsed.payload.redirect_url;
				return;
			}
			if (responseParsed.status === 200) {
				dispatch(demandsSetList({
					collection: responseParsed.payload.demands.collection,
					count: responseParsed.payload.demands.count
				}));
				setIsContentLoaded(true);
			} else if (responseParsed.status === 403) {
				dispatch(addToastMsg({
					message: t(`status.${responseParsed.statusText}`),
					theme: EnumStatusTheme.ERROR,
				}));
				navigate(PATHS.HOMEPAGE);
			} else if (responseParsed.error === 'access_denied') {
				dispatch(userLogout());
				dispatch(clearDemands());
				dispatch(addToastMsg({
					message: t('status.error_session'),
					theme: EnumStatusTheme.ERROR,
				}));
			} else {
				navigate(PATHS.ERROR._ROOT.replace(':code', '404'));
				throw new GetDemandsByBrokerError(responseParsed.message);
			}
		}).catch(error => {
			if (error.name !== 'AbortError') {
				setIsContentLoaded(true);
				throw new GetDemandsByBrokerError(error);
			}
		});
	};

	const noResultElementFilter = (
		<Paragraph
			className={styles.no_result}
		>
			<div className={styles.message}>
				<Image
					className={styles.images}
					src={noResultFilter}
				/>
				<span className={styles.title}>
					{t('format.capitalize', {
						text: t('general.filter.no_result_filter.title')
					})}
				</span>
				<span className={styles.description}>
					{t('format.capitalize', {
						text: t('general.filter.no_result_filter.description')
					})}
				</span>
			</div>
		</Paragraph >
	);

	const noResultElementInitial = (
		<Paragraph
			className={styles.no_result}
		>
			<div className={styles.message}>
				<Image
					className={styles.images}
					src={noResult}
				/>
				<span className={styles.title}>
					{t('format.capitalize', {
						text: t('page.demands.no_result.title')
					})}
				</span>
				<span className={styles.description}>
					{t('format.capitalize', {
						text: t('page.demands.no_result.description')
					})}
				</span>

				<Button
					className={styles.button}
					href={PATHS.SEARCH.DEMAND_ADD.LEGACY}
					iconLeft='plus'
					label={t('format.capitalize', {
						text: t('page.demands.add_demand')
					})}
				/>
			</div>
		</Paragraph >
	);

	const noResultElement = hasUsedFilter ? noResultElementFilter : noResultElementInitial;

	const modalUpdateDemandStateElement = isContentLoaded ? (
		<Modal
			hasHeader={false}
			isDesktopResolution={isDesktopResolution}
			isOpen={isOpenUpdateDemandStateModal}
			size={ModalSize.DEFAULT}
			onClose={() => {
				setIsOpenUpdateDemandStateModal(false);
			}}
		>
			<ModalUpdateDemandState
				demand={demandModalState?.id ? demandsGlobalState?.collection?.filter((demand: DemandJsonPrivate) => demand.id === demandModalState.id)[0] as DemandJsonPrivate : null}
				isDesktopResolution={isDesktopResolution}
				setIsOpenModal={setIsOpenUpdateDemandStateModal}
			/>
		</Modal>
	) : null;

	const modalDeleteDemandElement = isContentLoaded ? (
		<Modal
			hasHeader={false}
			isDesktopResolution={isDesktopResolution}
			isOpen={isOpenDeleteDemandModal}
			size={ModalSize.DEFAULT}
			onClose={() => {
				setIsOpenDeleteDemandModal(false);
			}}
		>
			<ModalDeleteDemand
				demandIds={demandIds}
				setIsOpenModal={setIsOpenDeleteDemandModal}
				setReinitSelection={setReinitSelection}
			/>
		</Modal>
	) : null;

	const modalFilterElement = isContentLoaded ? (
		<Modal
			hasHeader={true}
			isDesktopResolution={isDesktopResolution}
			isOpen={isOpenFilterModal}
			size={ModalSize.DEFAULT}
			title='Filtres'
			onClose={() => {
				setIsOpenFilterModal(false);
			}}
		>
			<ModalDemandsFilterMobile
				currentUserId={currentUserId}
				getDemandsByFilter={getDemandsByFilter}
				isDesktopResolution={isDesktopResolution}
				selected={selected}
				setHasUsedFilter={setHasUsedFilter}
				setIsOpenFilterModal={setIsOpenFilterModal}
				setSelected={setSelected}
			/>
		</Modal>
	) : null;

	const exportDemands = async (demandIds: number[]) => {
		await fetch(utils.getURL(FETCHES.private.demands.export, {
			':selectedids': `${demandIds}`,
		}), {
			...APP_CONF_VARS.request.default,
			headers: {
				...APP_CONF_VARS.request.default.headers,
				'Content-type': 'multipart/form-data',
			},
			method: 'POST'
		}).then((resp) => {
			return resp.blob();
		}).then(blob => {
			downloadCSVFile(blob, t('page.demands.export_demands'));
		}).catch(err => {
			console.warn('ERROR : ', err);
		});
	};

	const handleOnClickExport = (event: MouseEvent<HTMLElement>, args?: OnClickActionProps) => {
		exportDemands(args.ids);
	};

	const handleOnClickDeleteDemand = () => {
		setIsOpenDeleteDemandModal(true);
	};

	const [
		isDisabled,
		setIsDisabled
	] = useState(false);

	const [
		cssContentClasses,
		setCssContentClasses
	] = useState([
		styles.content
	]);

	useEffect(() => {
		if (demandIds.length) {
			const selectedDemands = demandsGlobalState.collection.filter((demand) => demandIds.includes(demand.id));
			let ownerAllSelectedDemands = true;
			selectedDemands.forEach((demand) => {
				if (demand.created_infos.user.id !== currentUserId) {
					ownerAllSelectedDemands = false;
				}
			});
			setCssContentClasses([
				styles.content,
				styles.content__selected
			]);
			setIsDisabled(!ownerAllSelectedDemands);
		} else {
			setCssContentClasses([
				styles.content
			]);
		}
	}, [
		demandIds,
	]);

	const configurationActionBar = {
		type: t('module.my_demand.name_other'),
		actions: <div className={styles.action_list}>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Square}
				hasBoxShadow={true}
				iconLeft={'file'}
				label={t('format.capitalize', {
					text: t('general.action.export')
				})}
				name='export'
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.PRIMARY}
				onClick={handleOnClickExport}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Square}
				disabled={isDisabled}
				hasBoxShadow={true}
				iconLeft={'trash-alt'}
				label={t('format.capitalize', {
					text: t('general.action.delete')
				})}
				name='delete'
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				onClick={handleOnClickDeleteDemand}
			/>
		</div>
	};

	const formatedData = isContentLoaded ? demandsGlobalState?.collection?.map((demand: DemandJsonPrivate) => {
		return dataUtils.flattenObject(demand);
	}) : [
	];

	const handleOnChangePagination = (event: MouseEvent<HTMLElement>) => {
		const target = event.target as HTMLElement;
		const content = target.closest(`.${styles.content}`);
		content.scrollTop = 0;
	};

	const demandsList = isContentLoaded ? (
		<Table
			actionBarClassName={styles.action_bar__container}
			className={styles.table}
			columns={columnTableConf}
			configurationActionBar={configurationActionBar}
			customNoResult={noResultElement}
			data={formatedData}
			identifier='table_demands'
			options={{
				canSelect: true,
				hasHeader: isDesktopResolution,
				pagination: {
					nbItemsPerPage: 10,
					onChange: (event) => {
						handleOnChangePagination(event);
					}
				},
			}}
			reinitSelection={reinitSelection}
			renderSubComponent={(props) => {
				if (props !== null) {
					const filteredDemandJson = demandsGlobalState?.collection?.filter((demand: DemandJsonPrivate) => demand.id === props.original.id);
					return filteredDemandJson.length ? (
						<DemandRow
							{...props}
							className={styles.demand_row}
							currentUserId={currentUserId}
							demandJson={filteredDemandJson[0] as DemandJsonPrivate}
							isDesktopResolution={isDesktopResolution}
							setDemandIds={setDemandIds}
							setDemandModalState={setDemandModalState}
							setIsOpenDeleteDemandModal={setIsOpenDeleteDemandModal}
							setIsOpenUpdateDemandStateModal={setIsOpenUpdateDemandStateModal}
						/>
					) : undefined;
				}
			}}
			setComponentSelectedIds={setDemandIds}
			setReinitSelection={setReinitSelection}
			theme='column'
		/>
	) : <ContentLoader />;

	const isPlural = demandsGlobalState?.collection.length > 1 ? true : false;

	const contentDemandsRef = useRef(null);

	return (
		<Page className={styles.PageDemands} >
			<div
				className={styles.PageDemands__content}
				ref={contentDemandsRef}
			>
				<Header
					contentDemandsRef={contentDemandsRef}
					currentUserId={currentUserId}
					isDesktopResolution={isDesktopResolution}
					selected={selected}
					setHasUsedFilter={setHasUsedFilter}
					setIsOpenFilterModal={setIsOpenFilterModal}
					setSelected={setSelected}
				/>
				<div className={cssContentClasses.join(' ')}>
					{demandsGlobalState?.collection.length && isContentLoaded ? (
						<h2 className={styles.subtitle}>
							{t('format.capitalize', {
								text: t(`page.demands.subtitle${isPlural ? '_other' : ''}`, {
									'%count%': demandsGlobalState?.collection.length
								})
							})}
						</h2>
					) : null}
					{demandsList}
				</div>
				{modalUpdateDemandStateElement}
				{modalDeleteDemandElement}
				{isDesktopResolution ? null : modalFilterElement}
			</div>
		</Page>
	);
}

const PageDemandsWithTranslation = withTranslation()(PageDemands);
export {
	PageDemandsWithTranslation as default,
	SelectedType,
};
