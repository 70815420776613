// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SCp7QMza{position:relative}.SCp7QMza input{padding:0.92857143rem 1.42857143rem 0.92857143rem 3.57142857rem}.CVX_jG9y input{padding:0.5rem 1.42857143rem 0.5rem 3.57142857rem}.EcVmuqzt input{padding:1.28571429rem 1.42857143rem 1.28571429rem 4.28571429rem}.cnZ_QYfM{border-radius:0.57142857rem;box-shadow:0.07142857rem 0.28571429rem 0.57142857rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.2),-0.07142857rem -0.28571429rem 0.57142857rem 0 hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.2)}.VBwdIgbk{color:var(--color-mine-shaft);font-size:1.42857143rem;position:absolute;top:50%;transform:translateY(-50%);z-index:2;right:1.42857143rem}.WbcTlYJK{font-size:1.42857143rem;margin-right:0.71428571rem}.FkOufann{left:0;position:absolute;top:100%;width:100%;z-index:3}@media screen and (min-width: 768Px)and (orientation: landscape){.FkOufann{font-size:1rem}}.FkOufann:after{background-color:var(--color-border-autosuggest);content:"";height:1Px;left:50%;position:absolute;top:0;transform:translateX(-50%);width:calc(100% - 1.14285714rem);z-index:4}.WBP36252{align-items:flex-start;display:flex;font-size:1rem;padding:1.07142857rem 1.57142857rem}.IwosNYNO input{border-bottom:0;border-radius:0.57142857rem 0.57142857rem 0 0}b{font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `SCp7QMza`,
	"size__small": `CVX_jG9y`,
	"size__big": `EcVmuqzt`,
	"shadow": `cnZ_QYfM`,
	"icon__right": `VBwdIgbk`,
	"itemIcon": `WbcTlYJK`,
	"panel": `FkOufann`,
	"paragraph": `WBP36252`,
	"results": `IwosNYNO`
};
export default ___CSS_LOADER_EXPORT___;
