import * as React from 'react';
import {
	ChangeEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	Controller,
	ControllerFieldState,
	ControllerRenderProps,
} from 'react-hook-form';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import formUtils from '@modules/formUtils';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';

//COMPONENTS
import Icon from '@components/icon';
import {
	InputTextProps,
} from '@components/form/input-text';
import InputLabel from '@components/form/input-label';
import {
	InputProps,
} from '@components/form/input';

// STYLES
import styles from './input-time-picker.module.scss';

interface MethodsProps {
	register?: (name: string, rules: object) => InputTimePickerProps;
	formState?: {
		errors?: {
			[key: string]: string;
			name?: string;
		};
	};
	setError?: (name?: string) => void;
	setValue?: (name?: string, value?: unknown) => void;
	clearErrors?: (name?: string) => void;
}

interface InputTimePickerProps extends Omit<InputTextProps, 'defaultValue' | 'value'> {
	defaultValue?: moment.Moment;
    format?: string;
    hasIcon?: boolean;
	methods?: MethodsProps;
	minuteStep?: number;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
    showHour?: boolean;
    showMinute?: boolean;
	rules?: object;
	value?: moment.Moment;
}

const InputTimePicker = ({
	className,
	customError,
	'data-testid': dataTestid,
	defaultValue = moment(),
	disabled,
	format = 'HH:mm',
	hasBorder = true,
	hasShadow = false,
	hasIcon = true,
	id,
	innerRef,
	label,
	methods,
	name,
	pattern,
	placeholder,
	placement = 'bottomLeft',
	required,
	showHour = true,
	showMinute = true,
	minuteStep = 5,
	rules,
	size,
	type,
	validationType,
	value,
}: InputTimePickerProps): JSX.Element => {
	const { t } = useTranslation();
	let register = null;
	const isControled = methods?.register;
	const registerRules = {
	} as InputProps['registerRules'];

	const hookRef = useRef<HTMLDivElement>(null);
	const localRef = innerRef || hookRef;
	const inputRef = useRef(null);

	/* istanbul ignore next */
	if (isControled) {
		if (validationType && required) {
			registerRules.required = {
				value: true,
				message: customError ? customError : t('general.form.input.error.default')
			};
			registerRules.pattern = {
				value: pattern || formUtils.rulesByType['default'],
				message: customError ? customError : t(`general.form.input.error.${type}`)
			};
		}

		register = methods.register(name, registerRules);
	}

	const [
		newValue,
		setNewValue
	] = useState(value ? value : defaultValue);

	/* istanbul ignore next */
	const valueToSet = value || defaultValue || newValue;
	/* istanbul ignore next */
	if (methods && valueToSet) {
		methods.setValue(name, valueToSet);
	}

	const [
		open,
		setOpen
	] = useState(false);

	/* istanbul ignore next */
	const handleOnChange = (value: moment.Moment) => {
		if (methods) {
			methods.setValue(name, value);
		}
		setNewValue(value);
		const minutesChanged = value.minutes() !== value.minutes();
		minutesChanged ? setOpen(false) : undefined;
	};

	const IconElement = hasIcon ? (
		<Icon
			data-testid={`${dataTestid}-icon`}
			fontStyle={EnumFontStyle.LIGHT}
			name="clock"
		/>
	) : null;

	const labelElement = label ? (
		<InputLabel data-testid={`${dataTestid}-label`}>{label}</InputLabel>
	) : null;

	const classes = [
		styles.time_picker_custom
	];

	const classesTimePicker = [
	] as string[];

	if (className) classes.push(className);
	if (size) classesTimePicker.push(`${'size__' + size}`);
	if (hasBorder) classesTimePicker.push('border');
	if (hasShadow) classesTimePicker.push('shadow');
	/* istanbul ignore next */
	if ((methods?.formState?.errors[name]) && required && !newValue && !value && !defaultValue) classes.push(styles.invalid);

	const errorMsgElement = (
		<div
			className={styles.error}
			data-error
			data-testid={`${dataTestid}-error`}
			role="alert"
		>
			{customError ? customError : t('general.form.input.error.time_picker')}
		</div>
	);

	/* istanbul ignore next */
	const TimePickerComponent = (field?: ControllerRenderProps, fieldState?: ControllerFieldState) => (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
			ref={field ? field.ref : localRef}
		>
			{labelElement}
			<TimePicker
				className={classesTimePicker.join(' ')}
				defaultValue={value ? value : moment()}
				disabled={disabled}
				format={format}
				id={id}
				inputIcon={IconElement}
				minuteStep={minuteStep}
				name={name}
				open={open}
				placeholder={placeholder}
				placement={placement}
				popupClassName={classes.join(' ')}
				ref={inputRef}
				showHour={showHour}
				showMinute={showMinute}
				showSecond={false}
				value={newValue}
				onChange={(newValue) => {
					/* istanbul ignore next */
					if (field) {
						field.onChange(newValue);
					}
					/* istanbul ignore next */
					handleOnChange(newValue);
				}}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
			/>
			{fieldState?.error && required && !newValue && !value && !defaultValue ? errorMsgElement : null}
		</div>
	);

	/* istanbul ignore next */
	return register ? (

		<Controller
			defaultValue={value ? value : moment()}
			name={name}
			render={/* istanbul ignore next */({ field, fieldState }) => TimePickerComponent(field, fieldState)}
			rules={rules}
		/>
	) : TimePickerComponent();
};

InputTimePicker.displayName = EnumComponentType.INPUT_TIME_PICKER;

export {
	InputTimePicker as default,
	InputTimePickerProps,
};
