import '@modules/logger';
import * as React from 'react';
import {
	StrictMode,
	Suspense,
} from 'react';
import {
	createRoot,
} from 'react-dom/client';
import {
	persistStore,
} from 'redux-persist';
import {
	PersistGate,
} from 'redux-persist/integration/react';
import {
	store,
} from '@stores/lpdipro/store';
import {
	Provider,
} from 'react-redux';
import {
	BrowserRouter as Router,
} from 'react-router-dom';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	I18nextProvider,
} from 'react-i18next';

// Modules
import i18n from '@modules/i18n';

// Page layouts
import LpdiProApp from '@layouts/_App/lpdipro';

// STYLES
// import styles from 'index.module';
const persistor = persistStore(store);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<StrictMode>
		<Provider store={store}>
			<PersistGate
				loading={null}
				persistor={persistor}
			>
				<Suspense fallback={<></>}>
					<I18nextProvider i18n={i18n}>
						<Router
							basename={APP_CONF_VARS.router.webapp.basename}
						>
							<LpdiProApp />
						</Router>
					</I18nextProvider>
				</Suspense>
			</PersistGate>
		</Provider>
	</StrictMode>
);
