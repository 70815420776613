import * as React from 'react';
import {
	MouseEvent,
	useState,
	RefObject,
	useEffect,
} from 'react';
import {
	matchRoutes,
	useLocation,
	useParams,
} from 'react-router-dom';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

// ROUTES
import {
	DEMAND_MANAGE_ROUTES,
} from '@routes/lpdipro/private/demands';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import FETCHES from '@routes/fetches';
import PATHS from '@routes/paths';

// TYPES
import {
	Demand,
	DemandJsonPrivate,
} from '@@types/Demand';

// CONFIG
import featuresFlagsConf from '@appConf/featuresFlags.conf';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumSwitchView,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumThemeLink,
} from '@enums/link.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';
import {
	EnumSteps,
} from '@enums/steps.enum';

// EXCEPTIONS
import SetUpdateDemandViewModeError from '@exceptions/UpdateDemandViewMode';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// SLICES
import {
	demandSwitchViewMode,
} from '@stores/_slices/demands';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';

// MODULES
import * as utils from '@modules/utils';
import {
	FeatureFlags,
} from '@modules/featureFlags';

// COMPONENTS
import Button from '@components/button';
import Link from '@components/link';
import Modal, {
	ModalSize,
} from '@components/modal';
import Sticky from '@components/sticky';
import Text from '@components/text';
import TabList from '@components/tab-list';
import Tab from '@components/tab-list/tab';

// LAYOUTS
import ModalFull from '@layouts/Modals/ModalFull';
import ModalShare from '@layouts/Modals/ModalShare';

// STYLES
import styles from './header.module.scss';

interface HeaderProps {
	actualLanguage: string;
	demandGlobalState: DemandJsonPrivate;
	isDesktopResolution: boolean;
	contentDemandRef: RefObject<HTMLDivElement>;
}

const Header = ({
	actualLanguage,
	demandGlobalState,
	isDesktopResolution,
	contentDemandRef,
}: HeaderProps): JSX.Element => {
	const { step } = useParams();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const user = useSelector((state: ReducerInstance) => state.user.instance);
	const navIsOpen = useSelector((state: ReducerInstance) => state.app.instance.navIsOpen);
	const flags = FeatureFlags(featuresFlagsConf, user);
	const [
		isOpenPreviewModal,
		setIsOpenPreviewModal
	] = useState(false);

	const [
		isOpenModalShare,
		setIsOpenModalShare
	] = useState(false);

	const [
		isDisabled,
		setIsDisabled
	] = useState(false);

	// Check if the actual route contains one of the 'manage' section
	const isManageRoute = matchRoutes(DEMAND_MANAGE_ROUTES, pathname);
	const commercializationStepName = step || 'matched';

	const demand = new Demand(demandGlobalState, actualLanguage);
	const dispatch = useDispatch();

	const isPreviewDisabled = demand.shared_demand_token ? false : true;

	const updateSettingsViewMode = async () => {
		clearToastMsgs();
		setIsDisabled(true);
		await fetch(utils.getURL(FETCHES.private.demands.update_view_mode, {
			':demandid': `${demand.id}`,
		}), {
			...APP_CONF_VARS.request.default,
			method: 'PATCH',
			body: JSON.stringify({
				view_mode: demandGlobalState?.settings?.display?.view_mode === EnumSwitchView.TABLE ? EnumSwitchView.CARD : EnumSwitchView.TABLE,
			}),
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
					throw new SetUpdateDemandViewModeError(responseParsed.statusText);
			}
		}).catch(err => {
			throw new SetUpdateDemandViewModeError(err);
		}).finally(() => {
			setIsDisabled(false);
		});
	};

	const titleElement = (
		<div className={styles.title}>
			<h1>
				{t('format.capitalize', {
					text: t('page.demand.private.title', {
						'%companyName%': demand?.recipient?.company?.name
					})
				})
				}
			</h1>
			<Text
				className={styles.ref}
				theme={EnumStatusTheme.SUCCESS}
			>
				{t('format.capitalize', {
					text: t('general.ref', {
						'%ref%': demand.id
					})
				})}
			</Text>
		</div>
	);

	const reportURL = utils.getURL(PATHS.DEMANDS.PRIVATE.REPORT, {
		':demandid': `${demand.id}`,
	});

	const manageURL = utils.getURL(PATHS.DEMANDS.PRIVATE.MANAGE.STEP._ROOT, {
		':demandid': `${demand.id}`,
		':step': `${commercializationStepName}`
	});

	const linkListElement = (
		<TabList className={styles.link_list}>
			<Tab isActive={/report/.test(location.pathname)}>
				<Link
					href={reportURL}
					theme={EnumThemeLink.TAB}
				>
					{t('format.capitalize', {
						text: t('page.demand.private.tabs.report.name')
					})}
				</Link>
			</Tab>
			<Tab isActive={/manage/.test(location.pathname)}>
				<Link
					href={manageURL}
					theme={EnumThemeLink.TAB}
				>
					{t('format.capitalize', {
						text: t('page.demand.private.tabs.manage.name')
					})}
				</Link>
			</Tab>
		</TabList>
	);

	const handleOnClickPreview = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setIsOpenPreviewModal(true);
	};

	const handleOnClickShare = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setIsOpenModalShare(true);
	};

	const segmentUrlCompany = demandGlobalState?.broker_company?.segment_url;
	const sharedDemandToken = demandGlobalState?.shared_demand_token;

	const publicUrlDemand = utils.getURL(`${process.env.DOMAIN_MESOFFRES}${APP_CONF_VARS.sfPathMesOffres}${PATHS.DEMANDS.PUBLIC.STEP._ROOT}`, {
		':segmenturl': segmentUrlCompany,
		':step': EnumSteps.PROPOSED,
		':token': sharedDemandToken,
	});
	const previewPublicUrlDemand = `${publicUrlDemand}?preview=true`;

	const handleOnClickSwitchView = async () => {
		dispatch(demandSwitchViewMode({
			demand_id: demand.id,
		}));

		await updateSettingsViewMode();
	};

	const [
		isSticky,
		setIsSticky
	] = useState(false);

	const [
		stickyCssClasses,
		setStickyCssClasses
	] = useState([
		styles.filter
	]);

	useEffect(() => {
		const baseClasses = [
			styles.filter
		];
		if (isSticky) {
			baseClasses.push(styles.filter__sticky);
			if (navIsOpen && isDesktopResolution) {
				baseClasses.push(styles.filter__sticky__navisopen);
			}
		}
		setStickyCssClasses(baseClasses);
	}, [
		navIsOpen,
		isSticky
	]);

	return (
		<>
			{isOpenPreviewModal ? (
				<Modal
					isDesktopResolution={isDesktopResolution}
					isOpen={isOpenPreviewModal}
					size={ModalSize.FULL}
					title={t('format.capitalize', {
						text: t('page.demand.private.modal_title')
					})}
					onClose={() => {
						setIsOpenPreviewModal(false);
					}}
				>
					<ModalFull>
						<iframe
							src={previewPublicUrlDemand}
						></iframe>
					</ModalFull>
				</Modal>
			) : undefined}
			{isOpenModalShare ? (
				<Modal
					isDesktopResolution={isDesktopResolution}
					isOpen={isOpenModalShare}
					size={ModalSize.DEFAULT}
					title={t('format.capitalize', {
						text: t('page.demand.private.modal_share_title')
					})}
					onClose={() => {
						setIsOpenModalShare(false);
					}}
				>
					<ModalShare
						demand={demandGlobalState}
						publicUrlDemand={publicUrlDemand}
					/>
				</Modal>
			) : undefined}
			<div className={styles.header}>
				{titleElement}
				{linkListElement}
				{isManageRoute ? (
					<Sticky
						className={stickyCssClasses.join(' ')}
						isDesktopResolution={true}
						isSticky={isSticky}
						scrollContainerRef={isDesktopResolution ? contentDemandRef : undefined}
						setIsSticky={setIsSticky}
					>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Default}
							counter={demandGlobalState?.counters?.steps[EnumSteps.MATCHED]}
							iconLeft={'bullseye-pointer'}
							iconStyle={EnumFontStyle.REGULAR}
							isActive={commercializationStepName === EnumSteps.MATCHED}
							label={t('format.capitalize', {
								text: t('page.demand.private.tabs.manage.steps.matched.title'),
							})}
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							to={utils.getURL(PATHS.DEMANDS.PRIVATE.MANAGE.STEP._ROOT, {
								':demandid': `${demand.id}`,
								':step': EnumSteps.MATCHED
							})}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Default}
							counter={demandGlobalState?.counters?.steps[EnumSteps.PROPOSED]}
							iconLeft={'share-arrow'}
							iconStyle={EnumFontStyle.REGULAR}
							isActive={commercializationStepName === EnumSteps.PROPOSED}
							label={t('format.capitalize', {
								text: t('page.demand.private.tabs.manage.steps.proposed.title'),
							})}
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							to={utils.getURL(PATHS.DEMANDS.PRIVATE.MANAGE.STEP._ROOT, {
								':demandid': `${demand.id}`,
								':step': EnumSteps.PROPOSED
							})}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Default}
							counter={demandGlobalState?.counters?.steps[EnumSteps.APPLIED]}
							disabled={flags.hasCRM ? true : false}
							iconLeft={'folder'}
							iconStyle={EnumFontStyle.REGULAR}
							isActive={commercializationStepName === EnumSteps.APPLIED}
							label={t('format.capitalize', {
								text: t('page.demand.private.tabs.manage.steps.applied.title'),
							})}
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							to={utils.getURL(PATHS.DEMANDS.PRIVATE.MANAGE.STEP._ROOT, {
								':demandid': `${demand.id}`,
								':step': EnumSteps.APPLIED
							})}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Default}
							counter={demandGlobalState?.counters?.steps[EnumSteps.REFUSED]}
							iconLeft={'times'}
							iconStyle={EnumFontStyle.REGULAR}
							isActive={commercializationStepName === EnumSteps.REFUSED}
							label={t('format.capitalize', {
								text: t('page.demand.private.tabs.manage.steps.refused.title'),
							})}
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							to={utils.getURL(PATHS.DEMANDS.PRIVATE.MANAGE.STEP._ROOT, {
								':demandid': `${demand.id}`,
								':step': EnumSteps.REFUSED
							})}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Default}
							counter={demandGlobalState?.counters?.steps[EnumSteps.IGNORED]}
							iconLeft={'trash-alt'}
							iconStyle={EnumFontStyle.REGULAR}
							isActive={commercializationStepName === EnumSteps.IGNORED}
							label={t('format.capitalize', {
								text: t('page.demand.private.tabs.manage.steps.ignored.title'),
							})}
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							to={utils.getURL(PATHS.DEMANDS.PRIVATE.MANAGE.STEP._ROOT, {
								':demandid': `${demand.id}`,
								':step': EnumSteps.IGNORED
							})}
						/>
						{isDesktopResolution ? (
							<>
								<Button
									className={`${styles.button} separator`}
									corners={EnumButtonCorners.Square}
									disabled={isPreviewDisabled}
									iconLeft={'eye'}
									size={EnumButtonSize.SMALL}
									theme={EnumTheme.PRIMARY}
									onClick={handleOnClickPreview}
								/>
								<Button
									className={styles.button}
									corners={EnumButtonCorners.Square}
									disabled={isPreviewDisabled}
									iconLeft={'user-arrow'}
									size={EnumButtonSize.SMALL}
									theme={EnumTheme.PRIMARY}
									onClick={handleOnClickShare}
								/>
								<Button
									className={styles.button}
									corners={EnumButtonCorners.Square}
									disabled={isDisabled}
									iconLeft={'switch'}
									label={t('format.capitalize', {
										text: demandGlobalState?.settings?.display?.view_mode === EnumSwitchView.TABLE ? t('general.action.card_view') : t('general.action.table_view')
									})}
									size={EnumButtonSize.SMALL}
									theme={EnumTheme.PRIMARY}
									onClick={handleOnClickSwitchView}
								/>
							</>
						) : null}
					</Sticky>
				) : null}
			</div>
		</>
	);
};

export default withTranslation()(Header);
