// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upfifFnv{display:flex;flex-direction:row;padding:1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.upfifFnv{padding:1.42857143rem}}.I1Zxeczq{display:flex;flex-direction:column;width:100%}.I1Zxeczq .hAd8jThi{color:var(--color-primary);margin-bottom:1.42857143rem;font-weight:600;font-size:1rem}.I1Zxeczq .ylbY2tDo{margin-bottom:1.42857143rem;font-size:0.85714286rem}.I1Zxeczq .TOTIeVYf{margin-bottom:1.42857143rem;font-size:1.14285714rem;font-weight:600;line-height:1.6em}.I1Zxeczq .TOTIeVYf:first-letter{display:block;text-transform:uppercase}._RdAAOxs{color:var(--color-boulder)}.LhYnGL5Q{margin-bottom:1.14285714rem;padding:0 0.85714286rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `upfifFnv`,
	"section__full": `I1Zxeczq`,
	"step_progress": `hAd8jThi`,
	"legend": `ylbY2tDo`,
	"title": `TOTIeVYf`,
	"grey": `_RdAAOxs`,
	"line": `LhYnGL5Q`
};
export default ___CSS_LOADER_EXPORT___;
