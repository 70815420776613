import * as React from 'react';
import {
	MouseEvent,
	ReactNode,
	useState,
} from 'react';

// STYLES
import styles from './contact-row.module.scss';

interface DemandsRowProps {
	canSelect?: boolean;
	className?: string;
	checkboxElement?: ReactNode;
	isDesktopResolution?: boolean;
	isSelected?: boolean;
}

const ContactRow = ({
	canSelect,
	className,
	checkboxElement,
	isSelected,
}: DemandsRowProps): JSX.Element => {

	const cssClasses = [
		styles.contact
	];

	const [
		activeElement,
		setActiveElement
	] = useState<string | null>(null);

	if (className) cssClasses.push(className);
	if (canSelect) cssClasses.push(styles.contact__can_select);
	if (isSelected) cssClasses.push(styles.contact__selected);

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		const id = event.currentTarget.id;
		setActiveElement(prev => (prev === id ? null : id));
	};

	const contactIdentityElement = (
		<div
			className={`${styles.cell_identity} ${activeElement === 'identity' ? styles.active : ''}`}
			id="identity"
			onClick={handleOnClick}
		>
			Ajouter un contact
		</div>
	);

	const roleElement = (
		<div
			className={`${styles.cell_role} ${activeElement === 'role' ? styles.active : ''}`}
			id="role"
			onClick={handleOnClick}
		>
			-
		</div>
	);

	const mandateElement = (
		<div
			className={`${styles.cell_mandate} ${activeElement === 'mandate' ? styles.active : ''}`}
			id="mandate"
			onClick={handleOnClick}
		>
			-
		</div>
	);

	const mandateNumberElement = (
		<div
			className={`${styles.cell_mandate_number} ${activeElement === 'mandate_number' ? styles.active : ''}`}
			id="mandate_number"
			onClick={handleOnClick}
		>
			-
		</div>
	);

	const refOfferElement = (
		<div
			className={`${styles.cell_ref} ${activeElement === 'ref' ? styles.active : ''}`}
			id="ref"
			onClick={handleOnClick}
		>
			-
		</div>
	);

	const receiptDateDesktop = (
		<div
			className={`${styles.cell_receipt} ${activeElement === 'receipt' ? styles.active : ''}`}
			id="receipt"
			onClick={handleOnClick}
		>
			-
		</div>
	);

	const linkElement = (
		<div
			className={`${styles.cell_link} ${activeElement === 'link' ? styles.active : ''}`}
			id="link"
			onClick={handleOnClick}
		>
			-
		</div>
	);

	const commentElement = (
		<div
			className={`${styles.cell_comment} ${activeElement === 'comment' ? styles.active : ''}`}
			id="comment"
			onClick={handleOnClick}
		>
			-
		</div>
	);

	return (
		<div
			className={cssClasses.join(' ')}
		>
			{canSelect ? <div className={styles.selection}>{checkboxElement}</div> : null}
			{contactIdentityElement}
			{roleElement}
			{mandateElement}
			{mandateNumberElement}
			{refOfferElement}
			{receiptDateDesktop}
			{linkElement}
			{commentElement}
		</div>
	);
};

export { ContactRow as default, };
