// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KjNNmzmn{display:flex;flex-direction:row;padding:1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.KjNNmzmn{padding:1.42857143rem}}.F33FDcsD{display:flex;flex-direction:column;width:100%}.F33FDcsD .KwfMrN50{color:var(--color-primary);margin-bottom:1.42857143rem;font-weight:600;font-size:1rem}.F33FDcsD .rtoILNbN{margin-bottom:1.42857143rem;font-size:0.85714286rem}.F33FDcsD ._8b5siNt{margin-bottom:1.42857143rem;font-size:1.14285714rem;font-weight:600;line-height:1.6em}.F33FDcsD ._8b5siNt:first-letter{display:block;text-transform:uppercase}.vXNkjjU6{color:var(--color-boulder)}.b3f8C0cf{margin-bottom:1.14285714rem;padding:0 0.85714286rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `KjNNmzmn`,
	"section__full": `F33FDcsD`,
	"step_progress": `KwfMrN50`,
	"legend": `rtoILNbN`,
	"title": `_8b5siNt`,
	"grey": `vXNkjjU6`,
	"line": `b3f8C0cf`
};
export default ___CSS_LOADER_EXPORT___;
