import {
	getURL,
} from '@modules/utils';
import FETCHES from '@routes/fetches';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	EnumQueryMethods,
} from '@enums/query.enum';
import {
	OfferStateProps,
} from '@layouts/Pages/PageOfferEntry';
import GetAddressesError from '@exceptions/GetAddressesError';
import AddOfferError from '@exceptions/AddOfferError';
import EditOfferError from '@exceptions/EditOfferError';
import GetOfferError from '@exceptions/GetOfferError';
import GetOfferEnumsError from '@exceptions/GetOfferEnumsError';

export const getAdresses = async (street_raw: string, city_raw: string) => {
	return await fetch(getURL(FETCHES.private.addresses, {
		':street_raw': `${street_raw}`,
		':city_raw': `${city_raw}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetAddressesError(error);
	});
};

export const createOffer = async (offerState: OfferStateProps) => {
	return await fetch(getURL(FETCHES.private.offers._ROOT), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.POST,
		body: JSON.stringify(offerState),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new AddOfferError(error);
	});
};

export const editOffer = async (offerState: OfferStateProps) => {
	return await fetch(getURL(FETCHES.private.offers.id, {
		':offerid': `${offerState.id}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.PUT,
		body: JSON.stringify(offerState),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new EditOfferError(error);
	});
};

export const getOffer = async (offerid: number) => {
	return await fetch(getURL(FETCHES.private.offers.edit, {
		':offerid': `${offerid}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetOfferError(error);
	});
};

export const getOfferEnums = async () => {
	return await fetch(getURL(FETCHES.private.offers.enums), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetOfferEnumsError(error);
	});
};
